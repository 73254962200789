.awards-content .awards-content__wrapper {
  display: grid;
  grid-template-columns: 1fr 2fr;
  padding: size(60px) 0 size(152px);

  @include vp-767 {
    grid-template-columns: 1fr;
    padding: size(42px) 0 size(105px);
  }
}

.awards-content {
  &__title {
    grid-column: 1 / -1;
    font-size: size(60px);
    font-weight: 400;
    line-height: size(65px);
    margin: 0 0 size(132px) 0;
    max-width: size(1200px);
    padding: 0 0 0 size(20px);
    white-space: break-spaces;

    @include vp-1023 {
      font-size: size(50px);
      line-height: size(55px);
      margin: 0 0 size(55px) 0;
      max-width: size(950px);
      padding: 0;
    }

    @include vp-767 {
      font-size: size(40px);
      line-height: size(45px);
      margin: 0 0 size(82px) 0;
      max-width: 100%;
    }
  }

  &__aside {
    grid-row: 2;
    flex: 0 0 auto;
    padding: 0 size(40px) 0 size(20px);
    width: size(420px);

    @include vp-1279 {
      width: 35%;
    }

    @include vp-767 {
      display: none;
      margin: 0 0 size(40px) 0;
      width: 100%;
    }

    @include vp-1023 {
      padding: 0 size(30px) 0 0;
      width: 40%;
    }
  }

  &__aside-item {
    position: sticky;
    top: 0;
    margin: 0;
    padding: 0;
    list-style: none;
    transition: all 0.2s ease-in-out;

    & li {
      padding: size(20px) 0 0;
    }
  }

  &__aside-link {
    cursor: pointer;
    font-size: size(40px);
    //line-height: size(45px);
    line-height: size(20px);
    transition: all $trans-default;

    @include vp-1023 {
      font-size: size(30px);
      line-height: size(35px);
    }

    @include vp-767 {
      font-size: size(20px);
      line-height: size(25px);
    }

    @media (hover: hover) {
      &:hover {
        color: $color-light-slate-blue;
      }
    }
  }
}
