.inner-grid {
  margin: size(150px) 0 0;

  @include vp-1023 {
    margin: size(100px) 0 0;
  }

  &--padding-middle {
    margin: size(60px) 0 0;

    @include vp-1023 {
      margin: size(100px) 0 0;
    }
  }

  &--no-margin {
    margin: 0;

    @include vp-1023 {
      margin: 0;
    }
  }

  &--margin-top {
    margin: size(150px) 0 0;

    @include vp-1023 {
      margin: size(100px) 0 0;
    }
  }

  &--margin-top-middle {
    margin: size(65px) 0 0;

    @include vp-1023 {
      margin: size(100px) 0 0;
    }
  }

  &--mt-150-40 {
    margin: size(150px) 0 0;

    @include vp-1023 {
      margin: size(40px) 0 0;
    }
  }

  &--mt-65-40 {
    margin: size(65px) 0 0;

    @include vp-1023 {
      margin: size(40px) 0 0;
    }
  }

  &--margin-bottom {
    margin-bottom: size(150px);

    @include vp-1023 {
      margin-bottom: size(100px);
    }
  }

  &__content {
    width: 66.2%;
    margin: 0 0 0 auto;

    :last-child {
      margin-bottom: 0;
    }

    @include vp-1023 {
      width: 100%;
    }
  }

  &__block {
    background-color: white;
    padding: size(20px);
    border-radius: size(20px);
    margin-top: size(65px);

    @include vp-1023 {
      margin-top: size(40px);
    }

    :first-child {
      margin-top: 0 !important;
    }
  }

  &__quote {
    margin-top: size(65px);

    @include vp-1023 {
      margin-top: size(40px);
    }
    
    :first-child {
      margin-top: 0 !important;
    }

    p {
      width: 100%;

      font-size: size(28px);
      line-height: size(30px);

      border-left: size(4px) solid $color-light-slate-blue;
      padding-left: size(30px);

      @include vp-1023 {
        font-size: size(20px);
        line-height: size(25px);
      }  
    }
  }

  &__picture {
    margin: size(65px) 0 0 0;

    @include vp-1023 {
      margin: size(40px) 0 0;
    }
  }

  &__picture-texts {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  &__picture-text {
    margin: 0;
    padding: size(10px) size(20px);
    font-size: size(15px);
    line-height: size(20px);
    color: $color-pink-swan;

    @include vp-767 {
      padding: size(10px) 0;
    }
  }

  &--no-padding {
    padding: 0;

    @include vp-1023 {
      padding: 0;
    }
  }

  &__aside--mobile-top {
    @include vp-1023 {
      grid-column: 1 / -1;
      padding: 0;
      margin: 0 0 size(40px);
    }
  }

  &__aside--mobile-bottom {
    @include vp-1023 {
      grid-row: 3 / 4;
      margin: size(20px) 0 0;

      &.inner-grid__aside--margin-big {
        margin: size(40px) 0 0;
      }
    }
  }

  &__aside {
    padding: 0;
  }

  &--aside-left {
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    .section-heading {
      grid-column: 1 / -1;
    }

    .container {
      grid-column: 1 / -1;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      column-gap: size(20px);

      @include vp-1023 {
        grid-template-columns: 1fr;
      }
    }

    .inner-grid__aside {
      grid-column: 1 / 2;

      @include vp-1023 {
        grid-column: 1 / -1;
      }
    }

    .inner-grid__content {
      grid-column: 2 / -1;
      width: 100%;

      @include vp-1023 {
        grid-column: 1 / -1;
      }
    }

    .inner-grid__content-max {
      grid-column: 1 / -1;
    }

    .inner-styled-list {
      //gap: size(5px);
      padding: 0 0 0 size(20px);

      @include vp-1023 {
        padding: 0;
        margin: 0 0 size(10px);
      }

      &__item {
        //margin: 0;
        //padding: 0;
      }
    }
  }

  & br.only-desktop {
    @include vp-1023 {
      display: none;
    }
  }

  & br.only-mobile {
    display: none;

    @include vp-767 {
      display: initial;
    }
  }
}
