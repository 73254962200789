.picture-bg {
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: size(20px);

  margin: 0 auto;

  @include vp-767 {
    height: size(400px);
  }

  &--mt-60-40 {
    margin-top: size(60px);
  }

  &--block1 {
    height: size(604px);
    background-color: $color-japonica;

    & svg {
      width: size(413px);
      height: size(460px);

      @include vp-767 {
        width: size(241px);
        height: size(226px);
      }
    }
  }

  &__description {
    font-size: size(15px);
    line-height: size(20px);
    color: $color-pink-swan;

    padding: 0 size(20px);
    margin: size(10px) 0;

    @include vp-767 {
      padding: 0;
    }
  }
}
