.custom-table {
  display: grid;
  grid-template-columns: 1fr;
  gap: 0;
  margin: 0;
  padding: 0;
  list-style: none;

  @include vp-767 {
    gap: size(20px) !important;
  }

  &--gap-25-20 {
    gap: size(25px);

    @include vp-767 {
      gap: size(20px);
    }
  }

  &--gap-0-5 {
    gap: 0;

    @include vp-767 {
      gap: size(5px);
    }
  }

  .custom-table-item {
    display: grid;
    grid-template-columns: 1fr 2fr;

    @include vp-767 {
      grid-template-columns: 1fr !important;
      border-top: none;
      padding: 0;
    }
  }

  &--height-135 .custom-table-item {
    min-height: size(135px);
  }

  &--column-ratio-1-3 .custom-table-item {
    grid-template-columns: 1fr 3fr;
  }

  &--tablet-column-1 {
    .custom-table-item {
      @include vp-1023 {
        grid-template-columns: 1fr;
        border-top: none;
        padding: 0;
      }
    }

    .custom-table-item__key p {
      @include vp-1023 {
        padding: 0;
        margin: size(15px) 0;
        font-size: size(30px);
        line-height: size(35px);
      }

      @include vp-767 {
        margin: size(10px) 0;
        font-size: size(20px);
        line-height: size(25px);
      }
    }
  }

  &--margin-top-small {
    margin-top: size(20px);

    @include vp-1023 {
      margin-top: size(20px);
    }

    @include vp-767 {
      margin-top: size(10px);
    }

    &.inner-styled-list--margin-top-fix {
      margin-top: size(20px);
    }
  }

  &--margin-top-middle {
    margin-top: size(40px);

    @include vp-1023 {
      margin-top: size(40px);
    }

    @include vp-767 {
      margin-top: size(20px);
    }

    &.inner-styled-list--margin-top-fix {
      margin-top: size(40px);
    }
  }

  &--margin-top-big {
    margin-top: size(65px);

    @include vp-1023 {
      margin-top: size(40px);
    }

    @include vp-767 {
      margin-top: size(40px);
    }

    &.inner-styled-list--margin-top-fix {
      margin-top: size(65px);
    }
  }

  &--margin-top-large {
    margin-top: size(150px);

    @include vp-767 {
      margin-top: size(100px);
    }
  }
}
