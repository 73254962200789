.aside {
  flex: 0 0 auto;
  padding: 0 size(40px) 0 size(20px);
  width: size(420px);

  @include vp-1279 {
    width: 35%;
  }

  @include vp-1023 {
    padding: 0 size(30px) 0 0;
    width: 40%;
  }

  @include vp-767 {
    margin: 0 0 size(40px) 0;
    width: 100%;
  }

  &__text {
    font-size: size(15px);
    line-height: size(20px);
    max-width: size(255px);

    @include vp-767 {
      max-width: unset;
    }

    p {
      margin: 0;

      &:not(:last-child) {
        margin: 0 0 size(30px) 0;
      }
    }
  }

  &__btn {
    margin: size(30px) 0 0 0;
    padding: size(8px) size(15px) size(12px);

    @include vp-767 {
      margin: size(30px) 0 0 0;
    }
  }

  &__box {
    align-items: flex-start;
    display: flex;
    flex-direction: column;

    &:not(:last-child) {
      margin: 0 0 size(28px) 0;
    }
  }

  &__heading {
    font-size: size(15px);
    line-height: size(20px);
    font-weight: 600;
    margin: 0 0 size(5px) 0;
  }

  &__link {
    font-size: size(15px);
    line-height: size(20px);
    transition: all $trans-default;
    text-decoration: underline;
    text-decoration-thickness: size(1px);
    text-underline-offset: size(4px);
    text-decoration-color: rgba(0, 0, 0, 0.15);
    transform: translateZ(0);
    padding: 0 0 size(2px) 0;

    &:not(:last-child) {
      margin: 0 0 size(7px) 0;
    }

    @media (hover: hover) {
      &:hover {
        color: $color-light-slate-blue;
        text-decoration-color: $color-transparent;
      }
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    gap: size(5px);

    & p {
      margin: 0;
      padding: size(8px) size(15px) size(12px);
      font-size: size(15px);
      line-height: size(20px);
      background-color: $color-default-white;
      border-radius: size(20px);
    }
  }
}
