.news-card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0;
  width: 100%;
  height: 100%;

  &__picture {
    border-radius: 5% / 10%;
    overflow: hidden;
    margin: 0 0 size(20px) 0;
    max-height: size(200px);

    @include vp-1023 {
      border-radius: 7.5% / 15%;
    }

    @include vp-1023 {
      border-radius: 7.5% / 15%;
    }

    @include vp-767 {
      height: size(200px);
    }

    &--radius-right {
      border-radius: 5% 25% 25% 5% / 10% 50% 50% 10%;

      @include vp-1023 {
        border-radius: 7.5% 25% 25% 7.5% / 15% 50% 50% 15%;
      }

      @include vp-767 {
        border-radius: 7.5% 25% 25% 7.5% / 15% 50% 50% 15%;
      }
    }

    &--radius-all {
      border-radius: 25% / 50%;

      @include vp-1023 {
        border-radius: 25% / 50%;
      }

      @include vp-767 {
        border-radius: 25% / 50%;
      }
    }
  }

  picture {
    display: block;
    width: 100%;
    height: 100%;
  }

  img {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }

  &__text {
    min-height: size(60px);
    margin: 0 0 size(20px) 0;
    padding: 0 size(20px);
    font-size: size(15px);
    line-height: size(20px);
    font-weight: 400;

    @include vp-1023 {
      font-size: size(15px);
    }

    @include vp-767 {
      font-size: size(15px);
    }

    p {
      margin: 0;
      white-space: break-spaces;

      &:not(:last-child) {
        margin: 0 0 size(15px) 0;
      }
    }
  }

  &__link {
    margin: auto 0 0;
    padding: 0 size(20px);
    font-size: size(15px);
    line-height: size(20px);
    transition: all $trans-default;
    text-decoration: underline;
    text-decoration-color: rgba(0, 0, 0, 0.15);
    text-underline-offset: size(5px);
    text-decoration-thickness: size(1px);

    &[href=""] {
      color: $color-pink-swan;
      text-decoration: none;
      pointer-events: none;
    }

    @media (hover: hover), screen and (min-width: 0 \0
    ) {
      &:hover:not([href=""]) {
        color: $color-light-slate-blue;
        text-decoration-color: $color-transparent;
      }
    }

    &::after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }
}
