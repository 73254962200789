.news {
  &__list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: size(39px) size(20px);
    padding: 0;
    margin: 0;
    list-style: none;

    @include vp-1023 {
      grid-template-columns: repeat(3, 1fr);
    }

    @include vp-767 {
      grid-template-columns: 1fr;
      gap: size(41px);
    }
  }

  &__item {
    display: flex;
    align-items: stretch;
  }
}
