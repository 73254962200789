.inner-video {
  position: relative;
  width: auto;
  height: auto;
  margin: size(65px) 0 0;
  display: flex;
  flex-direction: column;
  padding-top: size(600px);
  border-radius: 20px;
  overflow: hidden;

  iframe {
    border: none;
  }

  @include vp-767 {
    margin: size(40px) 0 0;
    padding-top: size(300px);
  }

  &--no-margin-top {
    margin: 0;

    @include vp-767 {
      margin: 0;
    }
  }

  &--margin-top-bottom {
    margin: size(65px) 0 size(65px);

    @include vp-767 {
      margin: size(40px) 0 size(40px);
    }
  }

  &--margin-top-extra-big {
    margin: size(150px) auto 0;

    @include vp-767 {
      margin: size(100px) auto 0;
    }
  }

  .video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  & + .video-description {
    margin: size(10px) 0;
    padding: 0 size(20px);
    font-size: size(15px);
    line-height: size(20px);
    color: $color-pink-swan;

    @include vp-767 {
      padding: 0;
    }
  }
}
