.main-contacts {
  margin: 0 0 size(150px) 0;
  padding: size(150px) 0 0;

  &.no-padding {
    padding: 0;
  }

  @include vp-1023 {
    margin: 0 0 size(100px) 0;
    padding: size(100px) 0 0;
  }

  &__aside.aside {
    @include vp-767 {
      margin: 0;
    }
  }

  &__content {
    display: flex;

    @include vp-767 {
      flex-direction: column;
    }
  }

  &__text {
    font-size: size(40px);
    line-height: size(45px);
    max-width: size(660px);
    padding: 0 0 0 size(20px);

    @include vp-1023 {
      font-size: size(30px);
      line-height: size(35px);
      padding: 0;
    }

    @include vp-1023 {
      font-size: size(40px);
      line-height: size(45px);
    }

    @include vp-767 {
      order: -1;
      margin: 0 0 size(100px);
    }

    p {
      margin: 0;

      &:not(:last-child) {
        margin: 0 0 size(45px) 0;
      }
    }

    & br.only-desktop {
      @include vp-767 {
        display: none;
      }
    }

    & br.only-mobile {
      display: none;

      @include vp-767 {
        display: initial;
      }
    }
  }

  &__link {
    cursor: pointer;
    text-decoration: underline;
    text-decoration-color: rgba(0, 0, 0, 0.15);
    text-underline-offset: size(9px);
    text-decoration-thickness: size(2px);
    transition: all $trans-default;
    transform: translateZ(0);
    padding: 0 0 5px 0;

    @media (hover: hover) {
      &:hover {
        color: $color-light-slate-blue;
        text-decoration-color: $color-transparent;
      }
    }
  }
}
