.contacts-buttons {
  align-items: center;
  bottom: size(20px);
  display: flex;
  position: fixed;
  right: size(20px);
  z-index: 10;

  @include vp-1023 {
    //bottom: unset;
    //position: relative;
    //right: unset;
  }

  &.is-hide {
    .contacts-buttons__request,
    .contacts-buttons__link {
      transform: translate3d(0, size(100px), 0);
    }
  }

  &__request {
    background-color: rgba($color-default-black, 0.7);
    backdrop-filter: blur(size(40px));
    border: none;
    border-radius: size(5px);
    color: $color-default-white;
    cursor: pointer;
    font-size: size(15px);
    line-height: size(20px);
    margin: 0 size(5px) 0 0;
    padding: size(14px) size(20px);
    transition: all $trans-default;
    transform: translate3d(0, 0, 0);

    @include vp-1023 {
      //background: rgba(255, 255, 255, 0.15);
      //backdrop-filter: unset;
      //border-radius: size(40px);
      //flex: 1 0 auto;
      //padding: size(22.5px) size(20px);
    }

    @media (hover: hover) {
      &:hover {
        background-color: $color-light-slate-blue;
      }
    }
  }

  &__link {
    align-items: center;
    background-color: rgba($color-default-black, 0.7);
    backdrop-filter: blur(size(40px));
    border-radius: 50%;
    display: flex;
    height: size(48px);
    justify-content: center;
    transition: all $trans-default;
    transform: translate3d(0, 0, 0);
    width: size(48px);

    svg {
      height: size(36px);
      width: size(36px);
    }

    @include vp-1023 {
      //background: rgba(255, 255, 255, 0.15);
      //backdrop-filter: unset;
      //height: size(66px);
      //width: size(66px);
    }

    @media (hover: hover) {
      &:hover {
        background-color: $color-light-slate-blue;
      }
    }
  }
}
