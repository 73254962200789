.inner-table {
  margin: size(150px) 0 0;

  @include vp-1023 {
    margin: size(100px) 0 0;
  }

  &__title {
    font-size: size(40px);
    line-height: size(45px);
    font-weight: 400;
    margin: size(-4px) 0 size(89px) auto;
    padding: 0 size(20px);
    width: 66.2%;

    @include vp-1023 {
      padding: 0;
      width: 100%;
      margin: 0 0 size(50px) 0;
    }

    @include vp-767 {
      margin: 0 0 size(40px) 0;
    }

    &--small {
      @include vp-767 {
        font-size: size(20px);
        line-height: size(25px);
      }
    }

    &.visually-hidden {
      left: 0;
      max-width: 100%;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &__item {
    align-items: flex-start;
    border-top: size(1px) solid rgba(0, 0, 0, 0.15);
    display: flex;
    padding: size(19px) 0 size(20px) 0;

    @include vp-1023 {
      border: none;
      flex-direction: column;
      padding: size(15px) 0;
    }

    &:last-child {
      padding: size(20px) 0;

      @include vp-1023 {
        padding: size(15px) 0 0;
      }
    }

    &:first-child {
      border-top: none;
      padding: 0 0 size(20px);

      @include vp-1023 {
        padding: 0 0 size(15px);
      }
    }
  }

  &__key {
    font-size: size(20px);
    flex: 0 0 auto;
    line-height: size(25px);
    padding: 0 0 0 size(20px);
    width: size(420px);

    @include vp-1023 {
      margin: 0 0 size(10px) 0;
      padding: 0;
      width: 100%;
    }
  }

  &__value {
    font-size: size(20px);
    line-height: size(25px);
    padding: 0 size(25px) 0 size(20px);
    margin: 0;
    min-height: size(75px);

    @include vp-1023 {
      font-size: size(15px);
      line-height: size(20px);
      min-height: unset;
      padding: 0;
    }

    @include vp-767 {
      font-size: size(15px);
      line-height: size(20px);
    }

    .inner-simple-box--margin-top-bottom {
      margin: size(25px) 0 size(40px);

      @include vp-1023 {
        margin: size(20px) 0 size(20px);
      }

      @include vp-767 {
        margin: size(20px) 0 size(20px);
      }
    }

    p {
      margin-bottom: size(20px);

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin: 0;
      }
    }
  }

  &__picture {
    margin: size(166px) 0 0 0;

    @include vp-1023 {
      margin: size(100px) 0 0;
    }
  }

  &--no-padding {
    padding: 0;

    @include vp-1023 {
      padding: 0;
    }
  }

  &--margin-top-big {
    margin-top: size(85px);

    @include vp-1023 {
      margin-top: size(100px);
    }
  }

  &--mt-85-40 {
    margin-top: size(85px);

    @include vp-1023 {
      margin-top: size(40px);
    }
  }

  & br.only-desktop {
    @include vp-1023 {
      display: none;
    }
  }

  & br.only-mobile {
    display: none;

    @include vp-767 {
      display: initial;
    }
  }
}
