.table {
  grid-column: 1/-1;
  margin: size(65px) 0 0;

  @include vp-1023 {
    margin: size(50px) 0 0;
  }

  &__item {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: size(20px);
    margin: 0 0 size(40px) size(20px);

    @include vp-1023 {
      grid-template-columns: 1fr;
      gap: size(11px);
      margin: 0 auto size(23px);
    }
  }

  &__item-left {
    font-weight: 400;
    font-size: size(40px);
    line-height: size(45px);

    @include vp-1023 {
      font-size: size(20px);
      line-height: size(25px);
    }

    & p {
      margin: 0;
    }
  }

  &__item-right {
    font-weight: 400;
    font-size: size(20px);
    line-height: size(25px);

    @include vp-1023 {
      font-size: size(15px);
      line-height: size(21px);
    }

    & p {
      margin: 0;
    }
  }
}
