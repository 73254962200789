.container {
  width: 100%;
  max-width: size(1440px);
  margin: 0 auto;
  padding: 0 size(20px);

  //@include vp-1439 {
  //  padding: 0 32px;
  //}
  //
  @include vp-1023 {
    padding: 0 size(30px);
  }
  //
  @include vp-767 {
    max-width: 100vw;
    padding: 0 size(20px);
  }
}
