.inner-sticky-block {
  &--inner {
    display: flex;
    flex-direction: column;

    @include vp-767 {
      display: block;
    }
  }

  &--margin-top {
    margin: size(90px) 0 0;

    @include vp-1023 {
      margin: 0;
    }
  }

  &--content-only {
    .inner-sticky-block__card {
      grid-column: 2 / -1;

      @include vp-1023 {
        grid-column: 1 / -1;
      }
    }
  }

  &__content {
    display: grid;
    grid-template-columns: 1fr minmax(auto, size(820px));
    margin: size(65px) 0 0;

    @include vp-1023 {
      grid-template-columns: 1fr 2fr;
      margin: size(40px) 0 0;
    }

    &--no-margin {
      margin: 0
    }

    &--margin-big {
      margin-top: 10rem;

      @include vp-1023 {
        grid-template-columns: 1fr 2fr;
        margin: size(65px) 0 0;
      }
    }

    @include vp-767 {
      display: block;
    }
  }

  &__title {
    &--hidden {
      display: none;
    }
  }

  &__aside {
    position: relative;
    grid-column: 1 / 2;
    padding: 0 size(20px) 0 0;

    @include vp-1279 {
      width: 100%;
    }

    @include vp-1023 {
      width: 100%;
      padding: 0 size(20px) 0 0;
    }

    @include vp-767 {
      display: none;
    }
  }

  &__picture {
    position: sticky;
    top: 0;
    border-radius: 50%;
  }

  &__card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    grid-column: 2 / 3;
    min-height: size(400px);
    padding: size(20px);
    border-radius: size(20px);

    &--mh-default {
      min-height: size(400px);
    }

    & .inner-sticky-block__video {
      display: none;

      @include vp-767 {
        display: initial;
      }
    }

    &--mh-265 {
      min-height: size(265px);
    }

    &--mh-310 {
      min-height: size(310px);
    }

    &--mh-365 {
      min-height: size(365px);
    }

    @include vp-1023 {
      min-height: auto;

      &--mh-default {
        min-height: auto;
      }
    }

    &--black {
      background-color: $color-default-black;
      color: $color-default-white;

      & .inner-sticky-block__author p {
        color: $color-white-smoke;
      }
    }

    &--orange {
      background-color: $color-carrot-orange;
      color: $color-default-black;
    }

    &--blue {
      color: $color-default-white;
      background-color: $color-han-purple;
    }

    &--pink {
      color: $color-default-white;
      background-color: $color-wild-watermelon;
    }

    &--lime {
      color: $color-default-white;
      background-color: $color-limerick;
    }

    &--white {
      background-color: $color-default-white;
      color: $color-default-black;
    }

    &--gainsboro-2 {
      background-color: $color-gainsboro-2;
      color: $color-default-black;
    }

    &--apple {
      background-color: $color-apple;
      color: $color-default-white;
    }
  }

  &__text {
    margin: 0 0 size(50px);
    font-size: size(40px);
    line-height: size(45px);
    font-weight: 400;
    white-space: break-spaces;

    & p {
      margin: 0 0 size(40px);

      &:last-child {
        margin: 0;
      }

      @include vp-1023 {
        margin: 0 0 size(20px);
      }
    }

    @include vp-1023 {
      margin: 0 0 size(20px);
      font-size: size(20px);
      line-height: size(25px);
    }
  }

  &__author {
    display: flex;
    align-items: center;
    line-height: size(20px);

    & .inner-sticky-block__picture {
      display: none;
      margin: 0 size(20px) 0 0;

      @include vp-767 {
        display: flex;
        min-width: size(80px);
        height: size(80px);

        picture {
          width: inherit;
          height: inherit;
        }

        img {
          object-fit: cover;
          width: inherit;
          height: inherit;
          transform: scale(1.03);
        }
      }
    }

    p {
      margin: 0;
    }
  }

  br.only-desktop {
    @include vp-1023 {
      display: none;
    }
  }

  br.only-tablet {
    display: none;

    @include vp-1023 {
      display: block;
    }

    @include vp-767 {
      display: none;
    }
  }

  br.only-mobile {
    display: none;

    @include vp-767 {
      display: block;
    }
  }

  &__video {
    position: sticky;
    top: 0;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: size(20px);
    max-height: size(400px);
    height: 100%;
    transition: filter $trans-default;
    transform: translateZ(0);

    @include vp-1023 {
      max-height: size(220px);
    }

    @include vp-767 {
      height: size(80px);
      max-width: size(80px);
      width: 100%;
      margin-right: size(20px);
    }

    &-picture {
      left: 0;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      z-index: -1;

      @include vp-1023 {
        height: 100%;
      }

      &::before {
        background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0.5) 100%);
        content: "";
        left: 0;
        height: 100%;
        position: absolute;
        top: 0;
        width: 100%;
      }
    }

    picture {
      display: block;
      height: 100%;
      width: 100%;
    }

    img {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }

    &-play {
      align-items: center;
      background-color: $color-transparent;
      backface-visibility: hidden;
      border: none;
      cursor: pointer;
      display: flex;
      height: 100%;
      justify-content: center;
      left: 50%;
      padding: 0;
      position: absolute;
      top: 50%;
      transform: translate3d(-50%, -50%, 0);
      width: 100%;

      @media (hover: hover), screen and (min-width: 0 \0
      ) {
        &:hover {
          .inner-sticky-block__video-icon {
            opacity: 1;
          }
        }
      }

      & svg {
        @include vp-767 {
          max-width: size(14px);
          max-height: size(14px);
        }
      }
    }

    &-icon {
      backface-visibility: hidden;
      height: size(58px);
      opacity: 0.4;
      pointer-events: none;
      transition: opacity $trans-default;
      transform: translateZ(0);
      width: size(50px);
    }


    &-author {
      align-items: center;
      color: $color-default-white;
      display: flex;
      flex-direction: column;
      font-size: size(15px);
      line-height: size(20px);
      margin: auto 0 0 0;
      padding: size(15px) 0 0 0;

      @include vp-767 {
        font-size: size(3px);
        line-height: size(4px);
        padding: size(35px) 0 0 0;
      }

      & br.only-desktop {
        @include vp-1023 {
          display: none;
        }
      }
    }
  }

  .inner-simple-cards:only-child {
    margin: size(40px) 0 0;
  }
}
