.blog-wrapper {
  width: size(860px);
  margin: auto;

  .inner-grid__content {
    width: 100%;
  }

  .quote {
    width: size(860px);

    @include vp-1023 {
      width: 100%;
    }
  }

  .table-of-contents {
    width: 100%;
  }

  .section-heading__count {
    position: absolute;
    left: size(40px);

    @include vp-1023 {
      position: static;
    }
  }

  @include vp-1023 {
    width: 100%;
  }

  &--detail-header {
      width: 100%;

      .inner-grid__content {
          width: size(860px);
          padding: size(20px);
          margin: auto;

          @include vp-1023 {
              width: 100%;
              padding: 0;
          }
      }
  }
}