.services {
  //padding: size(150px) 0 0;
  //
  //@include vp-1023 {
  //  padding: size(100px) 0 0;
  //}

  &__content {
    align-items: flex-start;
    display: flex;

    @include vp-767 {
      flex-direction: column;
    }
  }

  &__list {
    display: grid;
    gap: size(20px);
    grid-template-columns: 1fr 1fr;
    list-style: none;
    margin: 0;
    min-width: 0;
    padding: 0;
    width: 100%;

    @include vp-1023 {
      grid-template-columns: 1fr;
    }
  }

  &__aside.aside {
    @include vp-767 {
      padding: 0;
    }
  }

  &__link {
    background-color: $color-default-white;
    border-radius: size(20px);
    display: flex;
    font-size: size(20px);
    line-height: size(25px);
    min-height: size(150px);
    padding: size(20px);
    transition: box-shadow $trans-default;

    @include vp-767 {
      min-height: size(115px);
    }

    @media (hover: hover), screen and (min-width: 0 \0
    ) {
      &:hover {
        box-shadow: 0 size(20px) size(40px) rgba(0, 0, 0, 0.05);
      }
    }
  }
}
