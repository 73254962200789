.cooperate {
  //padding: size(150px) 0 0;
  //
  //@include vp-1023 {
  //  padding: size(100px) 0 0;
  //}

  &__content {
    display: flex;

    @include vp-767 {
      flex-direction: column;
    }
  }

  &__inner {
    padding: 0 0 0 size(20px);

    @include vp-1023 {
      padding: 0;
    }
  }

  &__list {
    list-style: none;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    margin: 0;
    padding: 0;

    &:not(:last-child) {
      margin: 0 0 size(20px);
    }
  }

  &__item {
    align-items: center;
    display: inline-flex;
    margin: size(5px) 0 0;

    &:not(:last-child) {
      margin: size(5px) size(15px) 0 0;

      @include vp-1023 {
        height: size(40px);
        margin: size(5px) size(12px) 0 0;
      }
    }
  }

  &__text {
    font-size: size(40px);
    line-height: size(45px);
    margin: 0;

    @include vp-1023 {
      font-size: size(25px);
      line-height: size(30px);
    }

    @include vp-767 {
      font-size: size(20px);
      line-height: size(25px);
    }

    &--title {
      color: $color-pink-swan;
    }
  }

  &__icon {
    height: size(31px);
    margin: auto 0 auto size(10px);
    width: auto;
  }

  &__aside.aside {
    @include vp-767 {
      padding: 0;
    }
  }
}
