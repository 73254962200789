.inner-simple-box {
  margin: size(65px) 0 0;
  padding: size(20px) 0;
  border-radius: size(20px);

  @include vp-1023 {
    padding: size(20px);
  }

  @include vp-767 {
    margin: size(40px) 0 0;
  }

  &--margin-top-bottom {
    margin: size(65px) 0 size(65px);

    @include vp-1023 {
      margin: size(40px) 0 size(40px);
    }

    @include vp-767 {
      margin: size(25px) 0 size(25px);
    }
  }

  &--bg-white {
    background-color: $color-default-white;
  }

  &--bg-snow {
    background-color: $color-snow;
  }

  &--inline {
    display: inline-flex;
    margin: 0;
    padding: size(10px) size(20px);

    .inner-simple-text {
      padding: 0;

      p {
        white-space: break-spaces;
      }
    }
  }

  &--margin-top-small {
    margin-top: size(20px);

    @include vp-1023 {
      margin-top: size(20px);
    }

    @include vp-767 {
      margin-top: size(10px);
    }

    &.inner-simple-box--margin-top-fix {
      margin-top: size(20px);
    }
  }

  &--margin-top-middle {
    margin-top: size(40px);

    @include vp-1023 {
      margin-top: size(40px);
    }

    @include vp-767 {
      margin-top: size(20px);
    }

    &.inner-simple-box--margin-top-fix {
      margin-top: size(40px);
    }
  }

  &--margin-top-big {
    margin-top: size(65px);

    @include vp-1023 {
      margin-top: size(40px);
    }

    @include vp-767 {
      margin-top: size(40px);
    }

    &.inner-simple-box--margin-top-fix {
      margin-top: size(65px);
    }
  }
}
