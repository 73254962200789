.team-list {
  margin: 0 0 size(30px) 0;

  @include vp-1023 {
    display: none;
  }

  &__list {
    align-items: center;
    display: grid;
    gap: size(15px);
    grid-template-columns: repeat(8, 1fr);
    list-style: none;
    margin: 0 0 size(20px);
    min-width: 0;
    padding: 0;
  }

  &__item {
    display: flex;
  }

  &__picture {
    border: size(0.5px) solid rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    cursor: pointer;
    height: size(50px);
    overflow: hidden;
    width: size(50px);
    transition: transform $trans-default;
    will-change: transform;

    @media (hover: hover), screen and (min-width: 0 \0
    ) {
      &:hover {
        transform: scale(1.3);
      }
    }

    picture {
      display: block;
      height: 100%;
      width: 100%;
    }

    img {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }

  &__description {
    align-items: center;
    color: $color-pink-swan;
    display: flex;
    flex-direction: column;
    font-size: size(15px);
    line-height: size(20px);
    transition: color $trans-default;

    &.is-active {
      color: $color-default-black;
    }
  }

  &__name {
    margin: 0 0 size(5px) 0;
  }
}
