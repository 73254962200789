.inner-blog-quote {
  &__card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: size(20px);
    border-radius: size(20px);
    max-width: 858px;
    margin: size(65px) auto 0;

    @include vp-1023 {
      grid-template-columns: 1fr 2fr;
      margin: size(40px) 0 0;
    }
  }

  &__color {
    &--black {
      background-color: $color-default-black;
      color: $color-default-white;

      & .inner-sticky-block__author p {
        color: $color-white-smoke;
      }
    }

    &--orange {
      background-color: $color-carrot-orange;
      color: $color-default-black;
      & svg {
        fill: $color-default-black;
      }
    }

    &--blue {
      color: $color-default-white;
      background-color: $color-han-purple;
      & svg {
        fill: $color-default-white;
      }
    }

    &--pink {
      color: $color-default-white;
      background-color: $color-wild-watermelon;
      & svg {
        fill: $color-default-white;
      }
    }

    &--lime {
      color: $color-default-white;
      background-color: $color-limerick;
      & svg {
        fill: $color-default-white;
      }
    }

    &--white {
      background-color: $color-default-white;
      color: $color-default-black;
      & svg {
        fill: $color-default-black;
      }
    }

    &--gainsboro-2 {
      background-color: $color-gainsboro-2;
      color: $color-default-black;
      & svg {
        fill: $color-default-black;
      }
    }

    &--apple {
      background-color: $color-apple;
      color: $color-default-white;
      & svg {
        fill: $color-default-white;
      }
    }
  }

  &__header {
    display: flex;
    justify-content: flex-end;
  }

  &__logo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 95px;
    
  }

  &__content {
    display: flex;

    @include vp-767 {
      flex-direction: column;
    }
  }

  &__img-wrapper {
    flex: 1;
    display: flex;
    justify-content: center;
    min-width: 50%;
  }

  &__text-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__text {
    display: flex;
    flex: 1;
  }

  &__text p {
    display: flex;
    margin: auto 0;
    font-size: 18px;
    line-height: 26px;

    @include vp-767 {
      margin-top: 60px;
      font-size: 15px;
      line-height: 20px;
    }
  }

  &__author {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
  }

  &__author-info {
    display: flex;
    flex-direction: column;
    align-items: end;
    gap: 7px;

    @include vp-767 {
      flex-direction: row;
    }
  }

  &__author-info p {
    width: fit-content;
    margin: 0;
    padding: 10px;
    background-color: color(srgb 1 1 1 / 0.4);
    border-radius: 27px;
    font-size: size(13px);
    line-height: size(16px);
    font-weight: 600;
  }
}
