.main-news {
  //padding: size(150px) 0 0;
  //

  &__swiper {
    @include vp-1023 {
      width: calc(100% + 2.6rem);
      margin-left: -1.3rem !important;
      overflow: inherit;
    }
  }

  &__list {
    display: grid !important;
    gap: size(20px);
    grid-template-columns: repeat(auto-fit, minmax(size(295px), 1fr));
    list-style: none;
    margin: 0;
    padding: 0 0 size(3px);

    @include vp-1023 {
      display: flex !important;
      gap: unset;
      width: size(295px) !important;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    height: auto;
    position: relative;

    @include vp-1023 {
      &:first-child {
        margin-left: 1.3rem;
      }
      &:last-child {
        margin-right: 1.3rem;
      }
    }
  }
}

.main-news .news-card {
  &__picture {
    @include vp-767 {
      height: size(150px);
    }
  }

  &__text,
  &__link {
    @include vp-767 {
      padding-left: 0;
    }
  }
}
