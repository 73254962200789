.news-content {
  display: grid;
  grid-template-columns: 1fr;

  &__title {
    font-size: size(60px);
    line-height: size(65px);
    font-weight: 400;
    margin: size(72px) 0 size(74px);
    padding: 0 size(20px);
    white-space: break-spaces;

    @include vp-767 {
      font-size: size(40px);
      line-height: size(45px);
      padding: 0;
      margin: size(42px) 0 size(45px);
    }
  }

  &__links {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: size(60px);
    margin: 0;
    padding: 0 size(20px);
    list-style: none;

    @include vp-1023 {
      grid-template-columns: repeat(2, 1fr);
    }

    @include vp-767 {
      grid-template-columns: 1fr;
      gap: size(45px);
      padding: 0;
    }
  }

  &__link {
    position: relative;
    padding: 0;
    font-size: size(60px);
    line-height: size(65px);
    transition: color $trans-default;

    @include vp-767 {
      font-size: size(40px);
      line-height: size(45px);
    }

    &::before {
      position: absolute;
      left: 0;
      bottom: size(-5px);
      width: 100%;
      height: size(3px);
      content: "";
      background-color: rgba($color-default-black, 0.15);
      transition: opacity $trans-default;

      @include vp-767 {
        bottom: size(-5px);
        height: size(2px);
      }
    }

    @media (hover: hover), screen and (min-width: 0 \0
    ) {
      &[href]:hover {
        color: $color-light-slate-blue;

        &::before {
          opacity: 0;
        }
      }
    }
  }

  &__list {
    margin: size(154px) 0 size(150px);

    @include vp-767 {
      margin: size(103px) 0 size(103px);
    }
  }
}
