.slider-scroll {
  padding-left: size(20px);
  padding-right: size(20px);

  @include vp-1023 {
    padding: 0;
  }

  &__wrapper {
    position: relative;
    width: 100%;
    min-height: size(509px);
    text-align: center;
    padding: size(76px);
    border-radius: size(20px);
    background-color: $color-default-white;

    .hero__btn {
      position: absolute;
      transform: translate(-50%, 0%);
      bottom: size(40px);
      left: 50%;
    }

    @include vp-1023 {
      min-height: size(291px);
      padding: size(40px) size(20px);

      .hero__btn {
        width: 80%;
      }
    }
  }

  &__title {
    margin: 0;
    font-size: size(40px);
    line-height: size(52px);
    font-weight: 400;

    @include vp-1023 {
      font-size: size(20px);
      line-height: size(26px);
    }
  }

  &__slider-wrapper {
    position: relative;
  }

  &__slide {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    // transition: opacity $trans-default;
    background-color: $color-default-white;
  }

  &__slide:first-child {
    opacity: 1;
  }

  &__title-block {
    display: flex;
    flex-wrap: wrap;
    column-gap: size(7px);
    justify-content: center;
    max-width: size(660px);
    margin: 0 auto;
    font-size: size(40px);
    line-height: size(52px);

    @include vp-1023 {
      font-size: size(20px);
      line-height: size(26px);
    }
  }

  &__title-item {
    display: inline-flex;
    align-items: center;
    column-gap: size(7px);
  }

  &__title-item svg,
  &__title-item img {
    height: size(24px);
    width: auto;
    margin-top: size(12px);
    border-radius: size(5px);
    flex-shrink: 0;
    transform: translateZ(0);
    backface-visibility: hidden;

    @include vp-1023 {
      height: size(18px);
      margin-top: size(4px);
    }
  }

  &__text {
    max-width: size(536px);
    margin: size(43px) auto 0 auto;

    @include vp-1023 {
      margin-top: size(32px);
      padding-left: size(4px);
      padding-right: size(4px);
    }
  }

  &__text p {
    margin: 0;
    font-size: size(15px);
    line-height: size(20px);
  }

  &__text p:not(:last-child) {
    margin-bottom: size(10px);
  }

  &__pagination-fractions {
    display: none;
    position: absolute;
    top: size(16px);
    right: size(16px);
    font-size: size(15px);
    line-height: size(20px);
    color: $color-pink-swan;

    @include vp-1023 {
      display: block;
    }
  }

  &__pagination {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    row-gap: size(16px);

    @include vp-1023 {
      display: none;
    }
  }

  &__pagination-btn {
    position: relative;
    width: size(7px);
    height: size(7px);
    border-radius: 50%;
    padding: 0;
    background-color: $color-default-black;
    opacity: 0.15;
    border: none;
    cursor: pointer;
    transition: opacity $trans-default;
  }

  &__pagination-btn::before {
    position: absolute;
    content: '';
    top: 50%;
    left: 50%;
    width: size(20px);
    height: size(20px);
    background-color: transparent;
    transform: translate(-50%, -50%);
  }

  &__pagination-btn.is-active {
    opacity: 1;
    pointer-events: none;
    cursor: default;
  }
}
