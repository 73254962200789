.sticky-block {
  display: grid;
  grid-template-columns: 1fr 2fr;
  padding: size(150px) 0 size(150px);

  @include vp-1023 {
    padding: size(100px) 0 size(100px);
  }

  &__aside {
    position: relative;
  }

  &__aside-item {
    position: sticky;
    top: 0;
    margin: 0;
    padding: 0 size(40px);
    list-style: none;

    li:not(:last-child) {
      margin: 0 0 size(20px);
    }
  }

  &__aside-link {
    font-size: 40px;
    line-height: 45px;
    transition: color $trans-default;

    @media (hover: hover) {
      &:hover {
        color: $color-light-slate-blue;
      }
    }
  }

  &__content {
    margin: 0 0 0 size(-5px);

    .section-heading--no-counter {
      margin: 0 0 size(40px);
    }
  }

  &--aside-desktop {
    @include vp-1023 {
      grid-template-columns: 1fr;

      .sticky-block__aside {
        display: none;
      }
    }
  }
}
