.link {
  border: size(1px) solid rgba($color-default-black, 0.15);
  border-radius: size(20px);
  font-size: size(15px);
  line-height: size(20px);
  padding: size(8px) size(14px) size(10px);
  transition: all $trans-default;

  &--radius-right {
    border-radius: size(5px) size(20px) size(20px) size(5px);
  }
  &--radius-left {
    border-radius: size(20px) size(5px) size(5px) size(20px);
  }

  &--radius-small {
    border-radius: size(5px);
  }

  @media (hover: hover), screen and (min-width: 0 \0
  ) {
    &[href]:hover {
      background-color: $color-default-black;
      color: $color-default-white;
    }
  }

  &--underline {
    position: relative;

    &::before {
      position: absolute;
      left: 0;
      bottom: size(-5px);
      width: 100%;
      height: size(3px);
      content: "";
      background-color: rgba($color-default-black, 0.15);
      transition: opacity $trans-default;

      @include vp-767 {
        bottom: size(-5px);
        height: size(2px);
      }
    }

    @media (hover: hover), screen and (min-width: 0 \0
    ) {
      &[href]:hover {
        &::before {
          opacity: 0;
        }
      }
    }
  }

  &--no-border {
    border: none;
  }

  &--big {
    padding: 0;
    font-size: size(60px);
    line-height: size(65px);

    @include vp-767 {
      font-size: size(40px);
      line-height: size(45px);
    }
  }

  &--transparent {
    background-color: transparent;
    transition: color $trans-default;

    @media (hover: hover), screen and (min-width: 0 \0
    ) {
      &[href]:hover {
        color: $color-light-slate-blue;
        background-color: transparent;
      }
    }
  }
}
