.form-block {
  &__wrapper {
    background-color: $color-default-white;
    padding: size(35px) size(40px) size(38px) size(40px);
    border-radius: size(20px);

    @include vp-1023 {
      padding: size(39px) size(20px) size(40px) size(20px);
    }
  }

  &__title {
    margin: 0 0 size(90px) 0;
    font-size: size(40px);
    line-height: size(45px);
    font-weight: 400;

    @include vp-1023 {
      margin-bottom: size(26px);
    }

    @include vp-767 {
      max-width: size(340px);
      font-size: size(20px);
      line-height: size(25px);
    }
  }

  form {
    max-width: size(1025px);
  }

  &__inputs-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: size(15px);
    margin-bottom: size(32px);

    @include vp-1023 {
      grid-template-columns: 1fr;
      gap: size(10px);
      margin-bottom: size(24px);
    }
  }

  .custom-input input {
    line-height: 1;
  }

  .custom-input__label {
    top: size(-8px);
  }

  &__button {
    position: relative;
    width: calc(50% - #{size(7px)});
    min-height: size(65px);
    border-radius: size(40px) !important;

    @include vp-1023 {
      width: 100%;
    }

    &.is-loading {
      color: $color-transparent;
      background: $color-pink-swan;
      pointer-events: none;

      .form-block__button-loader {
        display: block;
      }
    }

    &.is-success {
      color: $color-transparent;
      background: $color-emerald;
      pointer-events: none;

      .form-block__button-success {
        display: block;
        fill: none;
        stroke: $color-default-white;
      }
    }
  }

  &__button-icon {
    display: none;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
  }
}
