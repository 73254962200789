.inner-navigation-block {
  &--no-padding {
    padding: 0;

    @include vp-767 {
      padding: 0;
    }
  }

  &__title {
    margin: 0 0 size(48px);

    @include vp-767 {
      font-size: size(40px);
      line-height: size(45px);
    }
  }

  &__description {
    margin: 0 0 size(40px);

    @include vp-767 {
      margin: 0 0 size(20px);
    }
  }

  &__navigation-list {
    counter-reset: count-list;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 size(20px);

    margin: size(25px) 0 size(25px) auto;

    @include vp-767 {
      margin: size(5px) 0 size(5px) 0;
      padding: 0;
    }

    p {
      margin: 0;
      padding: size(8px) size(15px) size(12px);
      font-size: size(15px);
      line-height: size(20px);
      background-color: rgba($color-default-white, 0.6);
      border-radius: size(20px);
    }
  }


  &__navigation-item {
    border-bottom: 1px solid rgba($color: $color-light-slate-blue, $alpha: 0.2);
    margin: 0 0 size(8.5px);

    p {
      padding: 0;
      font-size: size(20px);
      line-height: size(25px);
      background-color: transparent;
      display: inline;

      @include vp-767 {
        font-size: size(15px);
        line-height: size(20px);
      }
    }

    a {
      text-decoration: none;
    }

    @include vp-767 {
      margin: 0 0 size(6px);
    }
  }

  &__navigation-item:last-child {
    margin: 0;
  }

  &__navigation-item::before {
    counter-increment: count-list;
    content: counter(count-list) ". ";
    color: $color-light-slate-blue;
    font-size: size(20px);
    line-height: size(25px);

    @include vp-767 {
      font-size: size(15px);
      line-height: size(20px);
    }
  }

  &__navigation-link {
    color: $color-light-slate-blue;
    font-size: size(20px);
    line-height: size(25px);

    @include vp-767 {
      font-size: 15px;
      line-height: size(20px);
    }
  }
}
