.inner-cards {
  padding: size(150px) 0 0;

  @include vp-767 {
    padding: size(100px) 0 0;
  }

  &__content {
    margin: 0 0 size(65px) auto;
    padding: 0 size(20px);
    width: 66.2%;

    @include vp-1023 {
      margin: 0 0 size(60px) 0;
      padding: 0;
      width: 100%;
    }

    @include vp-767 {
      margin: 0 0 size(40px) 0;
    }
  }

  &__title {
    font-size: size(40px);
    font-weight: 400;
    line-height: size(45px);
    margin: 0;
  }

  &__texts {
    margin: size(40px) 0 0 0;
    font-size: size(20px);
    line-height: size(25px);

    p {
      margin: 0;

      &:not(:last-child) {
        margin: 0 0 size(20px) 0;
      }
    }
  }

  &__list {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(3, 1fr);
    list-style: none;
    margin: 0;
    padding: 0;

    @include vp-1023 {
      grid-template-columns: 1fr 1fr;
    }

    @include vp-767 {
      grid-template-columns: 1fr;
    }

    &--half {
      grid-template-columns: 1fr 1fr;
      margin: 0 0 0 auto;
      max-width: size(820px);

      .inner-cards__item {
        min-height: size(145px);
      }
    }

    &--cards-height-165 {
      .inner-cards__item {
        min-height: size(165px);
      }
    }
  }

  &__item {
    background-color: $color-default-white;
    border-radius: size(20px);
    display: flex;
    flex-direction: column;
    min-height: size(125px);
    padding: size(20px);

    &--bottom-text p {
      margin: auto 0 0;
    }
  }

  &__heading {
    font-size: size(20px);
    font-weight: 400;
    line-height: size(25px);
    margin: 0 0 size(20px) 0;
  }

  &__description {
    color: $color-pink-swan;
    font-size: size(15px);
    line-height: size(20px);
    margin: 0;
    padding: 0;
  }
}
