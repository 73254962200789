.picture-text {

  &__wrapper {
    min-height: size(521px);
    height: 100%;

    border-radius: size(20px);
    overflow: hidden;

    position: relative;

    padding: 0;
    margin: size(65px) auto 0;

    @include vp-1023 {
      min-height: size(300px);

      margin: size(50px) auto 0;
    }

    @include vp-767 {
      min-height: size(453px);

      margin: size(40px) auto 0;
    }

    & img {
      position: absolute;

      height: auto;
      width: 100%;

      z-index: -1;
    }
  }

  &__picture {
    position: initial;
  }

  &__text {
    padding: size(97px) size(95px) size(100px) size(436px);

    @include vp-1023 {
      padding: size(50px) size(50px) size(20px) size(255px);
    }

    @include vp-767 {
      padding: size(30px) size(20px) size(30px) size(20px);
    }
  }

  &__title {
    font-size: size(40px);
    line-height: size(45px);
    font-weight: 400;

    margin: 0 0 size(40px);

    @include vp-1023 {
      font-size: size(20px);
      line-height: size(25px);

      margin: 0 0 size(10px);
    }

    @include vp-767 {
      display: none;
    }
  }

  &__texts {
    font-size: size(20px);
    line-height: size(25px);
    font-weight: 400;

    &:not(:last-child) {
      margin: 0 0 size(22px);
    }

    @include vp-1023 {
      font-size: size(12px);
      line-height: size(17px);

      &:not(:last-child) {
        margin: 0 0 size(6px);
      }
    }

    @include vp-767 {
      font-size: size(20px);
      line-height: size(25px);

      &:not(:last-child) {
        margin: 0 0 size(20px);
      }
    }
  }

  &__description {
    font-size: size(15px);
    line-height: size(20px);
    color: $color-pink-swan;

    padding: 0 size(20px);
    margin: size(10px) 0;

    @include vp-767 {
      padding: 0;
    }
  }
}
