// Colors
// ---------------------------------

// Default

$color-default-black: #000000;
$color-default-white: #ffffff;
$color-transparent: rgba(255, 255, 255, 0);

// Project palette
// для нейминга цветов используем https://www.htmlcsscolor.com/hex/6E2FF1

$color-neon-blue: #2c39f2;
$color-torch-red: #ff335f;
$color-sunset-orange: #f14e35;
$color-carrot-orange: #f7931d;
$color-international-orange: #ff5501;
$color-white-smoke: #f2f2f2;
$color-light-slate-blue: #a87dff;
$color-pink-swan: #b3b3b3;
$color-radical-red: #ff335f;
$color-emerald: #3ece5e;
$color-burnt-orange: #f86425;
$color-social-blue: #0077ff;
$color-social-red: #fc0d1b;
$color-navy-blue: #0052d8;
$color-navy-blue-2: #0079e9;
$color-navy-blue-3: #105DFB;
$color-persian-red: #d72e2b;
$color-medium-aquamarine: #65c9a3;
$color-han-purple: #4400e2;
$color-night-rider: #303030;
$color-manatee: #848494;
$color-wild-watermelon: #F25B78;
$color-moon-yellow: #FFBE18;
$color-snow: #FAFAFA;
$color-wild-watermelon: #F95864;
$color-limerick: #81BB3C;
$color-christi: #72BC19;
$color-medium-slate-blue: #7676DE;
$color-denim: #1E65B7;
$color-japonica: #d66a55;
$color-violet: #6e2ff1;
$color-crimson: #dd0a34;
$color-gainsboro: #e2e2e2;
$color-gainsboro-2: #dcdcdc;
$color-apple: #67b346;
$color-royal-blue: #5576e9;
$color-royal-blue-2: #4180ee;
$color-mahogany: #d83f44;
$color-dodger-blue: #0084ff;
$color-malachite: #13ca68;
$color-alice-blue: #dfeffb;
$color-salomie: #ffd57c;
$color-black-rock: #33333a;
$color-medium-aquamarine: #6ec6ae;
$color-egyptian-blue: #12409f;
$color-onahau: #bcefff;


// Opacity colors

$color-black-opacity-5: rgba(0, 0, 0, 0.05);
$color-black-opacity-50: rgba(0, 0, 0, 0.5);

// Gradient

$black-to-right: linear-gradient(90deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0) 75%);
$blue-to-bottom: linear-gradient(180deg, rgba(55, 187, 254, 1) 0%, rgba(0, 125, 187, 1) 100%);

// Typography
// -------------------------------

// переменная используемая в html для подключения скейлинга
$fz-default: 15px;

// у некоторых шрифтов в ios возникают проблемы с символом рубля
// https://www.artlebedev.ru/kovodstvo/sections/159/#13
$ff-rouble: "Rouble", "Arial", sans-serif;

$ff-graphik: "GraphikLC", "Arial", sans-serif;

// Animation
// ---------------------------------

$tf-default: ease;
$trans-default: 0.3s $tf-default;

// если требуется изинг отличный от $tf-default, то переменную называем не цифрой
$trans-modal: 0.6s cubic-bezier(0.55, 0, 0.1, 1);

$trans-600: 0.6s $tf-default;

// Viewports
// ---------------------------------

$vp-320: 320px;
$vp-375: 375px;
$vp-440: 440px;
$vp-768: 768px;
$vp-1024: 1024px;
$vp-1150: 1150px;
$vp-1280: 1280px;
$vp-1440: 1440px;
$vp-1920: 1920px;

// Retina
// ---------------------------------

$retina-dpi: 144dpi;
$retina-dppx: 1.5dppx;
