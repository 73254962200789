.simple-link {
  color: $color-light-slate-blue;
  cursor: pointer;
  text-decoration: underline;
  text-decoration-color: rgba(168, 125, 255, 0.2);
  text-underline-offset: size(5px);
  text-decoration-thickness: size(1px);
  transition: all $trans-default;
  transform: translateZ(0);
  padding: 0 0 size(5px) 0;

  &--font-big {
    text-underline-offset: size(10px);
    text-decoration-thickness: size(2px);

    @include vp-767 {
      text-underline-offset: size(5px);
      text-decoration-thickness: size(1px);
    }
  }

  &--no-underline {
    text-decoration: none;
    padding: 0;
  }

  &--no-color {
    color: inherit;
    text-decoration-color: rgba(0, 0, 0, 0.15);
  }

  @media (hover: hover) {
    &:hover {
      color: $color-light-slate-blue;
      text-decoration-color: rgba($color-light-slate-blue, 1);
    }

    &--no-color:hover {
      text-decoration-color: transparent;
    }
  }
}
