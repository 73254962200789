.social {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(size(85px), 1fr));
  grid-template-rows: auto;
  justify-items: center;
  gap: size(20px);
  max-width: size(610px);
  margin: 0 auto size(40px);
  padding: 0;

  @include vp-767 {
    grid-template-columns: repeat(auto-fit, minmax(size(56px), 1fr));
    gap: size(12px);
  }

  list-style: none;

  &__item {
    margin: 0;
  }

  &__link {
    display: flex;
    justify-content: center;
    align-items: center;
    width: size(85px);
    height: size(80px);
    position: relative;
    background: $color-social-blue;
    border-radius: size(10px);
    cursor: pointer;
    transition: all $trans-default;
    transform: translateZ(0);

    @include vp-767 {
      width: size(56px);
      height: size(56px);
    }

    &::after {
      content: "";
      border-radius: size(10px);
      background: linear-gradient(180deg, rgba(168, 125, 255, 1) 0%, rgba(168, 125, 255, 1) 100%);
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      transition: all $trans-default;
      transform: translateZ(0);
      z-index: 2;
      opacity: 0;
    }

    svg {
      height: size(48px);
      position: relative;
      width: size(48px);
      z-index: 3;
    }

    &--youtube {
      background: $color-social-red;
    }

    &--telegram {
      background: $blue-to-bottom;
    }

    &--vc {
      background-color: $color-wild-watermelon;
    }

    &--medium {
      background-color: $color-moon-yellow;
    }

    &--behance {
      background-color: $color-navy-blue-3;
    }

    @media (hover: hover), screen and (min-width: 0 \0
    ) {
      &:hover::after {
        opacity: 1;
      }
    }
  }
}
