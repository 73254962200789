.contacts-content {
  padding: size(68px) 0 size(153px) size(20px);

  @include vp-1023 {
    padding: size(40px) 0 size(107px);
  }

  &__text {
    font-size: size(40px);
    line-height: size(45px);
    margin: 0;

    p {
      margin: 0;
    }

    a {
      cursor: pointer;
      text-decoration: underline;
      text-decoration-color: rgba(0, 0, 0, 0.15);
      text-underline-offset: size(12px);
      text-decoration-thickness: size(2px);
      transition: all $trans-default;
      transform: translateZ(0);
      padding: 0 0 7px 0;
      color: black;

      @media (hover: hover) {
        &:hover {
          color: $color-light-slate-blue;
          text-decoration-color: $color-transparent;
        }
      }

      @include vp-1023 {
        text-underline-offset: size(8px);
      }
    }

    &:not(:first-of-type) {
      margin: size(45px) 0 0;
    }

    &--big {
      font-size: size(60px);
      line-height: size(65px);

      @include vp-1023 {
        font-size: size(40px);
        line-height: size(45px);
      }

      @include vp-767 {
        font-size: size(40px);
        line-height: size(45px);
      }
    }
  }

  &__maps {
    margin: size(150px) 0 0;

    @include vp-1023 {
      margin: size(102px) 0 0;
    }

    .inner-simple-title {
      max-width: size(800px);
      margin: 0 0 size(71px) auto;

      @include vp-1023 {
        max-width: 100%;
        margin: 0 0 size(40px);
      }
    }
  }

  &__footer {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 0;
    margin: size(150px) 0 0;

    @include vp-1023 {
      grid-template-columns: 1fr;
      gap: 0;
      margin: size(102px) 0 0;
    }
  }

  &__aside {
    padding: 0;


    @include vp-1023 {
      margin: 0 0 size(20px);
    }

    @include vp-767 {
      margin: 0 0 size(38px);
    }

    .aside__heading,
    .aside__link {
      margin: 0 0 size(4px);
    }
  }

  &__text-wrapper {
    max-width: size(760px);

    @include vp-1023 {
      max-width: 100%;
    }

    .contacts-content__link {
      text-underline-offset: size(10px);
    }
  }

  &__link {
    cursor: pointer;
    text-decoration: underline;
    text-decoration-color: rgba(0, 0, 0, 0.15);
    text-underline-offset: size(12px);
    text-decoration-thickness: size(2px);
    transition: all $trans-default;
    transform: translateZ(0);
    padding: 0 0 7px 0;

    @media (hover: hover) {
      &:hover {
        color: $color-light-slate-blue;
        text-decoration-color: $color-transparent;
      }
    }

    @include vp-1023 {
      text-underline-offset: size(8px);
    }
  }

  & br.only-desktop {
    @include vp-767 {
      display: none;
    }
  }

  & br.only-tablet-minus {
    display: none;

    @include vp-1023 {
      display: initial;
    }
  }

  & br.only-tablet-plus {
    @include vp-1023 {
      display: none;
    }
  }

  & br.only-mobile {
    display: none;

    @include vp-767 {
      display: initial;
    }
  }
}
