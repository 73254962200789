// antd rewrites

.ant-input:focus {
  border: none;
  box-shadow: none;
}

.ant-upload-select {
  width: 100%;
}

.auto-resize-textarea {
  textarea {
    transition: none !important;
    overflow: hidden;
    resize: none;
    line-height: 100%;
  }
}

.custom-input[aria-invalid], .custom-textarea[aria-invalid] {
  @extend .is-invalid;
}

// global

.only-preview {
  pointer-events: none;
}

.error-message {
  text-align: center;
  color: $color-torch-red !important;
  font-size: size(15px);
  line-height: size(20px);
  margin: size(15px) 0;
}
