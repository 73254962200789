.main-awards {
  //padding: size(150px) 0 0;
  //
  //@include vp-1023 {
  //  padding: size(100px) 0 0;
  //}

  &__content {
    display: flex;

    @include vp-767 {
      flex-direction: column;
    }
  }

  &__awards {
    margin: size(-20px) 0 0;
  }

  &__aside.aside {
    padding-right: size(170px);

    @include vp-767 {
      padding: 0;
    }
  }
}
