.modal--video-review {
  .modal__content {
    background-color: $color-transparent;
    padding: 0;
    width: auto;
  }

  .modal__close-btn {
    position: absolute;
    top: 0;
    right: size(-60px);
    z-index: 1;

    width: size(30px);
    height: size(30px);
    padding: 0;

    background: $color-transparent;
    border: none;
    cursor: pointer;

    @include vp-767 {
      right: 0;
      top: size(-40px);
    }
  }

  .modal-video {
    align-items: center;
    border-radius: size(10px);
    display: flex;
    justify-content: center;
    overflow: hidden;
    transform: translateZ(0);

    video {
      object-fit: cover;
      height: auto;
      max-height: calc(100vh - 15vh);
      max-width: size(393px);
      //width: 100%;
    }
  }
}
