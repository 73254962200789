.inner-principles {
  padding: size(150px) 0 0;

  @include vp-767 {
    padding: size(100px) 0 0;
  }

  &__content {
    display: flex;

    @include vp-1023 {
      flex-direction: column;
    }
  }

  &__aside {
    @include vp-1023 {
      order: 2;
      margin: size(40px) 0 0 0;
      padding: 0;
      width: 100%;

      .aside__text {
        max-width: unset;
      }
    }

    @include vp-767 {
      margin: size(42px) 0 0 0;
    }
  }

  &__title {
    font-size: size(40px);
    font-weight: 400;
    line-height: size(45px);
    margin: 0 0 size(39px);
    padding: 0 size(20px);

    @include vp-1023 {
      padding: 0;
    }
  }

  &__text {
    font-size: size(20px);
    line-height: size(25px);
    padding: 0 size(20px);

    @include vp-1023 {
      padding: 0;
    }

    p {
      margin: 0;

      &:not(:last-child) {
        margin: 0 0 size(23px) 0;
      }
    }
  }

  &__picture {
    margin: size(153px) 0 0 0;

    @include vp-1023 {
      margin: size(100px) 0 0;
    }
  }
}
