.dropdown {
  position: relative;
}

.menu {
  position: absolute;
  right: 0;

  list-style-type: none;
  margin: size(20px) 0;
  padding: size(12px);

  border: 1px solid white;
  border-radius: size(20px);
  background-color: white;
}

.menu > li {
  margin: 0;
  padding: size(16px) size(20px);
  border-radius: size(12px);
}

.menu > li:hover {
  background-color: $color-white-smoke;
}

.menu > li > a {
  width: 100%;
  height: 100%;
  text-align: left;
  white-space: nowrap;

  background: none;
  color: inherit;
  border: none;
  padding: 5px;
  margin: 0;
  font: inherit;
  cursor: pointer;
}