.inner-simple-links {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: size(25px) 0 size(25px);
  padding: 0 0 0 size(45px);
  font-size: size(20px);
  line-height: size(25px);
  list-style: none;

  &:first-child {
    margin: 0 0 size(25px) 0;
  }

  &:last-child {
    margin: size(25px) 0 0;
  }

  @include vp-767 {
    padding: 0 0 0 size(25px);
    font-size: size(15px);
    line-height: size(20px);
  }

  &--font-20-15-15 {
    @include vp-1023 {
      font-size: size(15px);
      line-height: size(20px);
    }
  }

  &--margin-top-40-40-40 {
    margin-top: size(40px);

    @include vp-1023 {
      margin-top: size(40px);
    }

    @include vp-767 {
      margin-top: size(40px);
    }
  }

  &--margin-top-40-20-20 {
    margin-top: size(40px);

    @include vp-1023 {
      margin-top: size(20px);
    }

    @include vp-767 {
      margin-top: size(20px);
    }
  }

  &--margin-top-25-25-5 {
    margin-top: size(25px);

    @include vp-1023 {
      margin-top: size(25px);
    }

    @include vp-767 {
      margin-top: size(5px);
    }
  }

  &--margin-top-25-5-5 {
    margin-top: size(25px);

    @include vp-1023 {
      margin-top: size(5px);
    }

    @include vp-767 {
      margin-top: size(5px);
    }
  }

  p {
    margin: 0;
    padding: size(8px) size(15px) size(12px);
    font-size: size(15px);
    line-height: size(20px);
    background-color: rgba($color-default-white, 0.6);
    border-radius: size(20px);
  }

  &__item {
    position: relative;
    width: 100%;

    &::after {
      position: absolute;
      content: "";
      top: size(13px);
      left: size(-13px);
      width: size(5px);
      height: size(5px);
      background-color: $color-light-slate-blue;
      border-radius: 50%;

      @include vp-767 {
        top: size(10px);
        left: size(-11px);
        width: size(4px);
        height: size(4px);
        border-radius: 50%;
      }
    }

    p {
      padding: 0;
      font-size: size(20px);
      line-height: size(25px);
      background-color: transparent;

      @include vp-767 {
        font-size: size(15px);
        line-height: size(20px);
      }
    }

  }

  &__link {
    cursor: pointer;
    text-decoration: underline;
    text-decoration-color: rgba(0, 0, 0, 0.15);
    text-underline-offset: size(6px);
    text-decoration-thickness: size(2px);
    transition: all $trans-default;
    transform: translateZ(0);
    padding: 0 0 5px 0;

    @media (hover: hover) {
      &:hover {
        color: $color-light-slate-blue;
        text-decoration-color: $color-transparent;
      }
    }
  }

  &--no-markers {
    padding: 0 size(20px);

    @include vp-1023 {
      padding: 0;
    }

    @include vp-767 {
      padding: 0;
    }

    li::after {
      display: none;
    }
  }
}
