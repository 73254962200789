.custom-table-item {
  border-top: size(1px) solid rgba(0, 0, 0, 0.15);
  padding: size(20px) 0;
  min-height: size(115px);

  @include vp-767 {
    border-top: none;
    padding: 0;
  }

  &:first-of-type {
    margin-top: 0;
    border-top: none;
  }

  &__key {
    p {
      padding: 0 size(20px);
      margin: 0;
      font-size: size(20px);
      line-height: size(25px);
    }

    @include vp-767 {
      p {
        padding: 0;
        margin: size(10px) 0 !important;
      }
    }
  }

  &__value {
    padding: 0 0 0 size(7px); // ПП

    @include vp-767 {
      .inner-simple-text {
        margin-bottom: size(40px);
      }
    }

    @include vp-1023 {
      padding: 0;
    }
  }

  .inner-styled-list {
    margin-bottom: size(-5px); // ПП

    p {
      font-size: size(15px);
      line-height: size(20px);
    }
  }

  .inner-simple-box {
    margin-bottom: size(20px);

    p {
      padding: 0;
    }
  }
}
