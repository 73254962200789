.inner-simple-title {
  width: 100%;
  margin: 0 0 size(40px) auto;
  padding: 0 size(20px);
  font-size: size(40px);
  line-height: size(45px);
  font-weight: 400;
  //white-space: break-spaces;

  @include vp-1023 {
    padding: 0;
  }

  @include vp-767 {
    margin: 0 0 size(20px) auto;
    font-size: size(20px);
    line-height: size(25px);
  }

  &--font-40-40-40 {
    font-size: size(40px);
    line-height: size(45px);

    @include vp-1023 {
      font-size: size(40px);
      line-height: size(45px);
    }

    @include vp-767 {
      font-size: size(40px);
      line-height: size(45px);
    }
  }

  &--font-20-20-15 {
    font-size: size(20px);
    line-height: size(25px);

    @include vp-1023 {
      font-size: size(20px);
      line-height: size(25px);
    }

    @include vp-767 {
      font-size: size(15px);
      line-height: size(20px);
    }
  }

  &--font-desktop {
    @include vp-767 {
      font-size: size(40px);
      line-height: size(45px);
    }
  }

  &--font-small {
    @include vp-767 {
      font-size: size(15px);
      line-height: size(20px);
    }
  }

  &--hidden {
    display: none;
  }

  &--no-margin {
    margin: 0;

    @include vp-1023 {
      margin: 0;
    }

    @include vp-767 {
      margin: 0;
    }
  }

  &--mobile-margin-middle {
    @include vp-767 {
      margin: 0 0 size(40px) auto;
    }
  }

  &--mobile-margin-small {
    @include vp-767 {
      margin: 0 0 size(20px) auto;
    }
  }

  &--no-padding {
    padding: 0;
  }

  &--no-padding-right {
    padding: 0 0 0 size(20px);
  }

  &--large {
    margin: 0 0 size(65px) auto;

    @include vp-1023 {
      margin: 0 0 size(40px) auto;
    }

    @include vp-767 {
      margin: 0 0 size(40px) auto;
      font-size: size(40px);
      line-height: size(45px);
    }
  }

  &--mb-65-40-40 {
    margin: 0 0 size(65px) auto;

    @include vp-1023 {
      margin: 0 0 size(40px) auto;
    }

    @include vp-767 {
      margin: 0 0 size(40px) auto;
    }
  }

  &--margin-top-150-100-100 {
    margin-top: size(150px);

    @include vp-1023 {
      margin-top: size(100px);
    }

    @include vp-767 {
      margin-top: size(100px);
    }
  }

  &--margin-top-40-40-40 {
    margin-top: size(40px);

    @include vp-1023 {
      margin-top: size(40px);
    }

    @include vp-767 {
      margin-top: size(20px);
    }
  }


  &--mobile-font-big {
    @include vp-767 {
      margin: 0 0 size(40px) auto;
      font-size: size(40px);
      line-height: size(45px);
    }
  }
}
