.project {
  &--margin-top-big {
    margin: size(65px) 0 0;

    @include vp-1023 {
      margin: size(40px) 0 0;
    }
  }

  &--height-big .project__text-item {
    min-height: size(175px);

    @include vp-1023 {
      min-height: auto;
    }
  }

  &--height-middle {
    .project__text-list {
      opacity: 1;
      transform: translate3d(0, 0, 0);

      @include vp-767 {
        margin: 0;
        padding: size(6px) 0 0 0;
      }
    }

    .project__text-title {
      font-size: size(20px);
      line-height: size(25px);
      margin-top: 0;
    }

    .project__text-note {
      margin: auto 0 size(9px) 0;

      @include vp-1023 {
        margin: 0 size(11px) 0 0;
      }
    }

    .project__text-item {
      min-height: size(155px);

      @include vp-1023 {
        min-height: auto;
      }
    }
  }

  &--height-small .project__text-item {
    min-height: size(120px);

    @include vp-1023 {
      min-height: auto;
    }
  }

  &__list {
    display: grid;
    gap: size(20px);
    grid-template-columns: repeat(3, 1fr);
    list-style: none;
    margin: 0;
    padding: 0;

    @include vp-1023 {
      grid-template-columns: repeat(2, 1fr);
    }

    @include vp-767 {
      grid-template-columns: 1fr;
    }

    .portfolio & {
      opacity: 0;
      transform: translate3d(0, size(50px), 0);

      &.is-active {
        opacity: 1;
        transition: all $trans-default;
        transform: translate3d(0, 0, 0);
      }
    }
  }

  &__list.is-filtered {
    .project__item--small-radius {
      .project__picture {
        border-radius: size(20px);
      }
    }

    .project__item--size-big {
      grid-column: span 1;

      &::before {
        padding: 100% 0 0;
      }

      .project__title {
        font-size: size(20px);
        line-height: size(25px);

        @include vp-1023 {
          font-size: size(18px);
          line-height: size(22px);
        }

        @include vp-767 {
          font-size: size(20px);
          line-height: size(25px);
        }
      }

      .project__picture img {
        object-position: right;

        @include vp-1023 {
          object-position: unset;
        }
      }
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    height: auto;
    position: relative;
    fill: transparent;
    padding: 0 !important;

    article {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      height: 100%;
      width: 100%;
      padding: size(20px);
    }

    &--round article {
      align-items: center;
      justify-content: flex-end;
      padding: size(45px) size(20px) size(65px);
    }

    &::before {
      content: "";
      position: relative;
      top: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 0 !important;
      padding: 100% 0 0;
    }

    &--size-big::before {
      padding: 48% 0 0;

      @include vp-1023 {
        padding: 100% 0 0;
      }
    }

    @include vp-1023 {
      width: auto;
      height: auto;
      min-height: unset;
    }

    @include vp-767 {
      width: auto;
      height: auto;
    }

    .project__picture {
      border-radius: size(20px);
      overflow: hidden;
    }

    &::after {
      content: "";
      left: 0;
      height: 100%;
      position: absolute;
      top: 0;
      width: 100%;
      opacity: 0;
      transition: all $trans-default;
      z-index: -2;
    }

    &--bg-orange {
      background-color: $color-sunset-orange;

      &::after {
        box-shadow: 0 size(20px) size(40px) rgba($color-sunset-orange, 0.5);
      }
    }

    &--bg-international-orange {
      background-color: $color-international-orange;

      &::after {
        box-shadow: 0 size(20px) size(40px) rgba($color-international-orange, 0.5);
      }
    }

    &--bg-radical-red {
      background-color: $color-radical-red;

      &::after {
        box-shadow: 0 size(20px) size(40px) rgba($color-radical-red, 0.5);
      }
    }

    &--bg-navy-blue {
      background-color: $color-navy-blue;

      &::after {
        box-shadow: 0 size(20px) size(40px) rgba($color-navy-blue, 0.5);
      }
    }

    &--bg-navy-blue-2 {
      background-color: $color-navy-blue-2;

      &::after {
        box-shadow: 0 size(20px) size(40px) rgba($color-navy-blue-2, 0.5);
      }
    }

    &--bg-persian-red {
      background-color: $color-persian-red;

      &::after {
        box-shadow: 0 size(20px) size(40px) rgba($color-persian-red, 0.5);
      }
    }

    &--bg-medium-aquamarine {
      background-color: $color-medium-aquamarine;

      &::after {
        box-shadow: 0 size(20px) size(40px) rgba($color-medium-aquamarine, 0.5);
      }
    }

    &--bg-pink-swan {
      background-color: $color-pink-swan;

      &::after {
        box-shadow: 0 size(20px) size(40px) rgba($color-pink-swan, 0.5);
      }
    }

    &--bg-color-christi {
      background-color: $color-christi;

      &::after {
        box-shadow: 0 size(20px) size(40px) rgba($color-christi, 0.5);
      }
    }

    &--bg-color-denim {
      background-color: $color-denim;

      &::after {
        box-shadow: 0 size(20px) size(40px) rgba($color-denim, 0.5);
      }
    }

    &--bg-crimson {
      background-color: $color-crimson;

      &::after {
        box-shadow: 0 size(20px) size(40px) rgba($color-crimson, 0.5);
      }
    }

    .project__shadow-link[href="#"] {
      pointer-events: none;
      cursor: pointer;
    }

    @media (hover: hover) {
      &:hover {
        &::after {
          opacity: 1;
        }

        .project__shadow-picture {
          opacity: 0.5;
        }
      }
    }
  }

  &__item--size-big {
    grid-column: span 2;
    border-radius: size(20px);

    @include vp-1023 {
      grid-column: span 1;
    }

    @include vp-767 {
      grid-column: span 1;
    }

    .project__title {
      font-size: size(40px);
      line-height: size(45px);
      max-width: 100%;

      @include vp-1023 {
        font-size: size(18px);
        line-height: size(22px);
      }

      @include vp-767 {
        font-size: size(20px);
        line-height: size(25px);
      }
    }
  }

  &__item--size-small {
    border-radius: size(20px);
    padding: size(20px);
  }

  &__item--round {
    align-items: center;
    border-radius: 50%;

    &::after {
      border-radius: 50%;
    }

    .project__title {
      max-width: size(380px);
      padding: 0;
      text-align: center;
    }

    .project__tags {
      margin: size(20px) 0 0;
    }
  }

  &__item--radius-right {
    .project__picture {
      border-radius: size(20px) size(200px) size(200px) size(20px);

      @include vp-1023 {
        border-radius: size(20px) size(100px) size(100px) size(20px);
      }

      @include vp-767 {
        border-radius: size(20px) size(200px) size(200px) size(20px);
      }
    }

    @include vp-767 {
      .project__title {
        max-width: 77%;
      }
    }
  }

  .project__item--small-radius {
    .project__picture {
      @include vp-1023 {
        border-radius: size(20px);
      }
    }

    @include vp-767 {
      .project__title {
        max-width: 100%;
      }
    }
  }

  &__icon {
    height: size(120px);
    margin: 0 0 auto 0;
    width: size(120px);

    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__picture {
    left: 0;
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;

    picture {
      display: block;
      height: 100%;
      width: 100%;
    }

    img {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }

  &__title {
    font-size: size(20px);
    line-height: size(25px);
    font-weight: 400;
    color: $color-default-white;
    margin: 0;
    white-space: break-spaces;

    @include vp-1023 {
      font-size: size(18px);
      line-height: size(22px);
    }

    @include vp-767 {
      font-size: size(20px);
      line-height: size(25px);
    }

    & br.only-desktop {
      @include vp-1023 {
        display: none;
      }
    }

    & br.only-mobile {
      display: none;

      @include vp-767 {
        display: initial;
      }
    }
  }

  &__tags {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: size(5px) 0;
    margin: auto 0 0 0;
  }

  &__tag {
    background: rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(size(40px));
    border-radius: size(20px);
    color: $color-default-white;
    font-size: size(15px);
    line-height: size(20px);
    padding: size(9px) size(15px) size(11px);
    position: relative;
    transition: all $trans-default;
    transform: translate3d(0, 0, 0);
    z-index: 6;
    cursor: pointer;

    @include vp-1023 {
      font-size: size(13px);
    }

    @include vp-767 {
      font-size: size(15px);
    }


    @media (hover: hover), screen and (min-width: 0 \0
    ) {
      &:hover {
        background-color: $color-default-white;
        color: $color-default-black;
      }
    }

    &:not(:last-child) {
      margin: 0 size(5px) 0 0;
    }
  }

  &__shadow-link {
    &::before {
      content: "";
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 5;
    }
  }

  &__shadow-picture {
    filter: blur(40px);
    opacity: 0;
    top: size(20px);
    transition: all $trans-default;
    z-index: -2;
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
  }

  &__text-list {
    display: flex;
    flex-direction: column;
    margin: size(40px) 0 0 0;
    padding: 0;
    opacity: 0;
    transform: translate3d(0, size(50px), 0);

    @include vp-767 {
      margin: size(110px) 0 0 0;
    }

    &.is-active {
      opacity: 1;
      transition: all $trans-default;
      transform: translate3d(0, 0, 0);
    }

    &.is-empty {
      margin: 0;
    }
  }

  &__text-item {
    display: flex;
    align-content: flex-start;

    @include vp-1023 {
      flex-wrap: wrap;
    }

    &:not(:last-child) {
      margin: 0 0 size(40px) 0;

      @include vp-1023 {
        margin: 0 0 size(20px) 0;
      }
    }

    //&:first-child {
    //  margin-top: size(150px);
    //}
  }

  &__text-left {
    display: flex;
    flex-direction: column;
    padding: 0 size(20px);
    width: size(440px);

    @include vp-1023 {
      align-items: baseline;
      flex-direction: row;
      margin: size(10px) 0 size(10px) 0;
      padding: 0;
      width: 100%;
      height: min-content;
    }
  }

  &__text-middle {
    width: size(590px);
  }

  &__text-full {
    width: size(590px);
    flex: 1 0 auto;
    justify-content: flex-end;
    @include vp-767 {
      width: 100%;
    }
  }

  &__text-right {
    display: flex;
    flex: 1 0 auto;
    justify-content: flex-end;
    padding: 0 size(20px) 0 size(20px);
    margin: size(-5px) 0 0;

    &--mobile-none {
      @include vp-1023 {
        display: none;
      }
    }
  }

  &__text-title {
    font-size: size(40px);
    font-weight: 400;
    line-height: size(45px);
    margin: size(-5px) 0 size(40px) 0;

    @include vp-1023 {
      font-size: size(20px);
      line-height: size(25px);
      margin: 0 auto 0 0;
    }
  }

  &__text-note {
    color: $color-pink-swan;
    font-size: size(15px);
    line-height: size(20px);

    @include vp-1023 {
      margin: 0 size(13px) 0 0;
    }
  }

  &__text-description {
    //display: flex;
    //align-items: center;
    //flex-direction: column;
    display: block;
    min-height: size(50px);
    font-size: size(20px);
    line-height: size(25px);
    margin: 0 0 size(20px);

    p {
      margin-top: 0;

      &:last-child {
        margin-bottom: 0;
      }
    }

    @include vp-1023 {
      min-height: auto;
      font-size: size(15px);
      line-height: size(20px);
      margin: 0;
    }

    & br.only-desktop {
      @include vp-1023 {
        display: none;
      }
    }

    & br.only-mobile {
      display: none;

      @include vp-767 {
        display: initial;
      }
    }
  }

  &__text-wrapper {
    display: flex;
    flex-direction: column;

    .project__text-description {
      white-space: break-spaces;
      width: 100%;
      margin: 0;
      min-height: auto;
    }
  }

  &--text-gap-25-5-5 .project__text-wrapper {
    gap: size(25px);

    @include vp-1023 {
      gap: size(5px);
    }

    @include vp-767 {
      gap: size(5px);
    }
  }

  &__text-year {
    font-size: size(40px);
    line-height: size(45px);

    @include vp-1023 {
      font-size: size(20px);
      line-height: size(25px);
    }

    &--desktop-none {
      display: none;

      @include vp-1023 {
        display: block;
      }
    }
  }

  &__text-tags {
    display: flex;
    flex-wrap: wrap;
    margin: size(20px) 0 0;
  }

  &__text-tag {
    background-color: $color-default-white;
    backdrop-filter: blur(size(40px));
    border-radius: size(20px);
    font-size: size(15px);
    line-height: size(20px);
    padding: size(9px) size(15px) size(11px);
    transition: all $trans-default;
    transform: translate3d(0, 0, 0);
    cursor: pointer;

    &:not(:last-child) {
      margin: 0 size(5px) 0 0;
    }

    @media (hover: hover), screen and (min-width: 0 \0
    ) {
      &:hover {
        background-color: $color-default-black;
        color: $color-default-white;
      }
    }
  }

  &--tablet-font-middle {
    .project__text-title,
    .project__text-year {
      @include vp-1023 {
        font-size: size(25px);
        line-height: size(30px);
      }

      @include vp-767 {
        font-size: size(20px);
        line-height: size(25px);
      }
    }

    .project__text-description {
      @include vp-1023 {
        font-size: size(20px);
        line-height: size(25px);
      }

      @include vp-767 {
        font-size: size(15px);
        line-height: size(20px);
      }
    }
  }
}
