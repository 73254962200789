.partners-form {
  margin: 0 size(20px) size(20px);
  background-color: $color-default-white;
  border-radius: size(20px);
  @include vp-1023 {
    padding: size(20px) 1.3333333333rem size(20px) 1.3333333333rem;
    margin: 0;
    border-radius: 0;
  }
  padding: size(65px) size(175px) size(65px) size(40px);

  &__subtitle {
    color: $color-pink-swan;
    font-size: size(20px);
    font-weight: 400;
    line-height: size(25px);
    margin-bottom: size(20px)
  }
  &__contact {
    display: grid;
    grid-template-columns: auto auto;
    column-gap: size(15px);
    @include vp-1023 {
      grid-template-columns: auto;
    }
  }
}