.inner-hero {
  &__text {
    font-size: size(20px);
    line-height: size(25px);
    margin: 0 0 size(65px) auto;
    padding: 0 size(20px);
    width: 66.2%;

    @include vp-1023 {
      font-size: size(18px);
      line-height: size(23px);
      margin: 0 0 size(50px) 0;
      padding: 0;
      width: 100%;
    }

    @include vp-767 {
      font-size: size(15px);
      line-height: size(20px);
      margin: 0 0 size(42px) 0;
    }

    &--bottom {
      margin: size(65px) 0 0 auto;

      @include vp-1023 {
        margin: size(50px) 0 0 0;
      }

      @include vp-767 {
        margin: size(42px) 0 0 0;
      }
    }

    p {
      margin: 0;

      &:not(:last-child) {
        margin: 0 0 size(25px) 0;

        @include vp-767 {
          margin: 0 0 size(30px) 0;
        }
      }
    }
  }
}
