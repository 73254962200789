.custom-select {
  position: relative;

  width: 100%;
  padding: size(7.5px) 0;

  &__label {
    color: rgba($color-default-black, 0.3);
    font-size: size(15px);
    line-height: size(20px);
    left: size(20px);
    top: 50%;
    position: absolute;
    pointer-events: none;
    transform: translateY(-50%);
    transition: all $trans-default;
  }

  &__placeholder {
    position: absolute;
    top: 50%;
    left: size(10px);

    transform: translateY(-50%);

    transition: transform $trans-default, opacity $trans-default;

    pointer-events: none;
  }

  select {
    position: absolute;

    width: size(1px);
    height: size(1px);
    margin: size(-1px);
    padding: 0;
    overflow: hidden;

    white-space: nowrap;

    border: 0;

    clip: rect(0 0 0 0);
    clip-path: inset(100%);
  }

  &__button {
    display: flex;
    align-items: center;
    width: 100%;
    height: size(65px);
    padding: size(5px) size(24px) size(5px) size(20px);

    background-color: $color-white-smoke;
    border: none;
    border-radius: size(10px);
    outline: none;
    cursor: pointer;

    transition: all $trans-default;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    &:hover {
      .custom-select__icon {
        opacity: 1;
      }

      + .custom-select__label {
        color: $color-default-black;
      }
    }

    //&:focus {
    //  .custom-select__icon {
    //    opacity: 1;
    //  }
    //
    //  + .custom-select__label {
    //    color: $color-default-black;
    //  }
    //}
  }

  &__list {
    background-color: $color-default-white;
    bottom: size(-2.5px);
    border-radius: size(10px);
    box-shadow: 0 size(10px) size(40px) rgba(0, 0, 0, 0.1);
    opacity: 0;
    list-style: none;
    margin: 0;
    max-height: size(240px);
    padding: size(15px) size(20px);
    position: absolute;
    overflow-y: auto;
    visibility: hidden;
    transform: translateY(100%);
    transition: visibility $trans-default, opacity $trans-default;
    width: 100%;
    z-index: 1;
  }

  &__item {
    font-size: size(15px);
    line-height: size(20px);
    padding: size(7.5px) 0;

    outline: none;
    cursor: pointer;

    transition: color $trans-default;

    &[aria-selected="true"] {
      background-color: rgba($color-default-white, 0.2);

      @media (hover: hover), screen and (min-width: 0 \0
      ) {
        &:hover {
          background-color: rgba($color-default-white, 0.2);
        }
      }
    }

    @media (hover: hover), screen and (min-width: 0 \0
    ) {
      &:hover,
      &:focus {
        color: $color-light-slate-blue;
      }
    }
  }

  &__error {
    position: absolute;
    bottom: 0;
    left: 0;

    color: $color-torch-red;

    opacity: 0;

    transition: opacity 0.1s ease;
  }

  &__text {
    flex-shrink: 0;
    width: 80%;
    overflow: hidden;

    text-align: left;
    white-space: nowrap;
    text-overflow: ellipsis;

    opacity: 0;

    transition: opacity $trans-default 0.1s;

    br {
      display: none;
    }
  }

  &__icon {
    margin-left: auto;
    flex-shrink: 0;
    transition: all $trans-default;
    opacity: 0.3;

    img {
      width: size(12px);
      height: size(8px);
    }

    //background-color: $color-default-black;
    //background: url("/img/sprite/icon-select-arrow.svg") no-repeat center;
    //clip-path: polygon(100% 0, 100% 20%, 50% 100%, 0 20%, 0 0, 50% 80%);
  }

  &.open-up {
    .custom-select__list {
      bottom: auto;
      top: 0;
      transform: translateY(calc(-100% + #{size(30px)}));
    }
  }

  &.is-open {
    .custom-select__list {
      visibility: visible;
      opacity: 1;
    }

    .custom-select__icon {
      transform: rotate(180deg);
    }

    .custom-select__label {
      //opacity: 0;
    }
  }

  &.not-empty {
    .custom-select__text {
      opacity: 1;
    }

    .custom-select__label {
      opacity: 0;
    }

    .custom-select__placeholder {
      opacity: 0;
    }
  }

  &.is-invalid {
    .custom-select__error {
      opacity: 1;
    }

    .custom-select__button {
      border: size(2px) solid $color-torch-red;

      &:focus,
      &:hover {
        border: size(2px) solid rgba($color-torch-red, 0.5);
      }
    }
  }

  /* &.is-valid {
    .custom-select__button {
      border: 2px solid $color-eucalyptus;

      &:focus,
      &:hover {
        border: 2px solid rgba($color-eucalyptus, 0.5);
      }
    }
  } */

  &--placeholder {
    .custom-select__label {
      transform: translateY(calc(-50% - #{size(40px)}));
      opacity: 1;
    }

    &.is-open .custom-select__label {
      opacity: 1;
    }

    &.not-empty .custom-select__label {
      opacity: 1;
    }
  }

  &.slide-up.not-empty,
  &.slide-up.is-open {
    .custom-select__label {
      transform: translateY(calc(-50% - #{size(40px)}));
      opacity: 1;
    }

    .custom-select__text {
      opacity: 1;
    }
  }
}
