.inner-simple-list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: size(25px) 0 size(25px) auto;
  padding: 0 0 0 size(45px);
  list-style: none;

  &.step-description:not(:last-child) {
    @include vp-767 {
      margin-bottom: size(20px);
    }
  }

  @include vp-1023 {
    margin: size(25px) 0 size(25px);
    padding: 0 0 0 size(20px);
  }

  @include vp-767 {
    margin: size(5px) 0 size(5px) 0;
  }

  &--my-25-30 {
    margin: size(25px) 0;

    @include vp-767 {
      margin: size(30px) 0;
    }
  }

  &--mobile-margin-big {
    @include vp-767 {
      margin: size(25px) 0 size(25px) 0;
      &:first-child {
        margin: 0 0 size(25px) 0;
      }
    }
  }

  &--mobile-margin-moderate {
    @include vp-767 {
      margin: size(31px) 0 size(31px) 0;
    }
  }

  &--margin-top-middle {
    margin: size(40px) 0 size(25px);

    @include vp-767 {
      margin: size(40px) 0 size(25px) 0;
    }
  }

  &--no-top-margin {
    margin-top: 0;

    @include vp-767 {
      margin-top: 0;
    }
  }

  &--no-margin {
    margin: 0;

    @include vp-767 {
      margin: 0;
    }
  }

  &--margin-small {
    margin: size(10px) 0;
  }

  &--margin-middle {
    margin: size(20px) 0;

    @include vp-767 {
      margin: size(10px) 0;
    }
  }

  p {
    margin: 0;
    padding: size(8px) size(15px) size(12px);
    font-size: size(15px);
    line-height: size(20px);
    background-color: rgba($color-default-white, 0.6);
    border-radius: size(20px);
  }

  &__item {
    position: relative;
    width: 100%;
    padding: 0 0 0 size(5px);

    &:last-of-type {
      margin: 0;
    }

    @include vp-767 {
      padding: 0 0 0 size(2px);
    }

    p {
      padding: 0;
      font-size: size(20px);
      line-height: size(25px);
      background-color: transparent;

      @include vp-767 {
        font-size: size(15px);
        line-height: size(20px);
      }
    }

    &::after {
      position: absolute;
      content: "";
      top: size(13px);
      left: size(-13px);
      width: size(5px);
      height: size(5px);
      background-color: $color-default-black;
      border-radius: 50%;

      @include vp-767 {
        top: size(10px);
        left: size(-11px);
        width: size(4px);
        height: size(4px);
        border-radius: 50%;
      }
    }
  }
}
