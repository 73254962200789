.table-of-contents {
  &__content {
      width: 66.2%;
      margin: 0 0 0 auto;

      :last-child {
          margin-bottom: 0;
      }
    
      @include vp-1023 {
          width: 100%;
          padding: size(0px);
      }
  }

  &__list {
      padding: 0;

      display: flex;
      flex-direction: column;
      gap: size(20px);

      list-style: none;

      @include vp-1023 {
          gap: size(10px);
      }
  }

  &__list-link {
      font-size: size(28px);
      font-weight: 400;
      color: $color-light-slate-blue;
      line-height: size(30px);
      cursor: pointer;
      
      text-decoration: underline;
      text-decoration-color: $color-transparent;
      text-decoration-thickness: size(1px);
      text-underline-offset: size(4px);

      transition: all $trans-default;
      transform: translateZ(0);

      @include vp-1023 {
          font-size: size(20px);
          line-height: size(25px);
      }

      @media (hover: hover) {
          &:hover {
              text-decoration-color: $color-light-slate-blue  ;
          }
      }
  }
}