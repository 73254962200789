.sandwich {
  align-items: center;
  background-color: transparent;
  display: none;
  padding: 0;
  position: relative;
  z-index: 25;

  @include vp-1023 {
    border: size(1px) solid rgba($color-default-black, 0.15);
    border-radius: size(5px);
    display: flex;
    height: size(40px);
    position: relative;
    transition: all $trans-default;
    width: size(40px);
  }

  &.is-active {
    border: size(1px) solid rgba(255, 255, 255, 0.15);

    .sandwich__menu {
      background-color: rgba($color-default-black, 0);
    }

    .sandwich__menu::after {
      background-color: $color-default-white;
      top: 0;
      transform: rotate(45deg);
      transition: background-color 0.3s, top 0.3s cubic-bezier(0.23, 1, 0.32, 1), transform 0.3s 0.3s cubic-bezier(0.23, 1, 0.32, 1);
    }

    .sandwich__menu::before {
      background-color: $color-default-white;
      bottom: 0;
      transform: rotate(-45deg);
      transition: background-color 0.3s, bottom 0.3s cubic-bezier(0.23, 1, 0.32, 1), transform 0.3s 0.3s cubic-bezier(0.23, 1, 0.32, 1);
    }
  }

  &__menu {
    background-color: $color-default-black;
    height: size(2px);
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    transition: all 0s 0.3s;
    top: 50%;
    width: size(18px);

    &::before {
      background-color: $color-default-black;
      bottom: size(5px);
      content: "";
      height: size(2px);
      left: 0;
      position: absolute;
      transition: background-color 0.3s, bottom 0.3s 0.3s cubic-bezier(0.23, 1, 0.32, 1), transform 0.3s cubic-bezier(0.23, 1, 0.32, 1);
      width: size(18px);
    }

    &::after {
      background-color: $color-default-black;
      content: "";
      position: absolute;
      left: 0;
      top: size(5px);
      transition: background-color 0.3s, top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
      height: size(2px);
      width: size(18px);
    }
  }
  &__menu--simple {
    width: size(18px);
    height: size(2px);
    left: 50%;
    top: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    background-color: $color-default-black;
    transition: background-color 0.3s, bottom 0.3s cubic-bezier(0.23, 1, 0.32, 1), transform 0.3s 0.3s cubic-bezier(0.23, 1, 0.32, 1);



    &::before {
      background-color: $color-default-black;
      bottom: size(5px);
      content: "";
      height: size(2px);
      left: 0;
      position: absolute;
      width: size(18px);
      transition: background-color 0.3s, bottom 0.3s cubic-bezier(0.23, 1, 0.32, 1), transform 0.3s 0.3s cubic-bezier(0.23, 1, 0.32, 1);
    }

    &::after {
      background-color: $color-default-black;
      content: "";
      position: absolute;
      left: 0;
      top: size(5px);
      height: size(2px);
      width: size(18px);
      transition: background-color 0.3s, bottom 0.3s cubic-bezier(0.23, 1, 0.32, 1), transform 0.3s 0.3s cubic-bezier(0.23, 1, 0.32, 1);
    }

    &.is-active {
      background-color: $color-default-white;
    
      &::after {
        background-color: $color-default-white;
      }
  
      &::before {
        background-color: $color-default-white;
      }
    }
  }
}
