.inner-image-text {
  margin: size(65px) 0 0;

  @include vp-1023 {
    margin: size(40px) 0 0;
  }

  &--no-margin {
    margin: 0;
  }

  &--margin-top-40 {
    margin-top: size(40px);
  }

  &--block-1 {
    .inner-image-text__image {
      width: size(710px);
      top: size(-20px);
      left: size(-20px);

      @include vp-767 {
        width: size(400px);
        top: 0;
        left: 0;
      }
    }

    .inner-image-text__description {
      @include vp-1023 {
        padding: size(62px) size(20px);
      }
    }
  }

  &--block-2 {
    .inner-image-text__image {
      width: size(632px);
      top: size(38px);
      left: size(28px);

      @include vp-767 {
        width: size(400px);
        top: size(17px);
        left: 0;
      }
    }

    .inner-image-text__description {
      padding: size(98px) 0;

      @include vp-1023 {
        padding: size(89px) size(20px) size(86px);
      }
    }
  }

  &--block-3 {
    .inner-image-text__image {
      width: size(851px);
      top: size(107px);
      left: 0;

      @include vp-1023 {
        top: auto;
      }

      @include vp-767 {
        width: size(400px);
        top: 0;
        left: 0;
      }
    }

    .inner-image-text__description {
      padding: size(98px) 0;

      @include vp-1023 {
        padding: size(75px) size(20px);
      }
    }
  }

  &--block-4 {
    .inner-image-text__image {
      width: size(606px);
      top: size(42px);
      left: size(40px);

      @include vp-767 {
        width: size(400px);
        top: size(50px);
        left: 0;
      }
    }

    .inner-image-text__description {
      padding: size(82px) 0;

      @include vp-1023 {
        padding: size(87px) size(20px);
      }
    }
  }

  &--block-5 {
    .inner-image-text__image {
      width: size(532px);
      top: size(60px);
      left: 0;

      @include vp-767 {
        width: size(400px);
        top: size(75px);
      }
    }

    .inner-image-text__description {
      padding: size(120px) 0;

      @include vp-1023 {
        padding: size(70px) size(20px);
      }
    }
  }

  &--block-6 {
    .inner-image-text__image {
      width: size(758px);
      top: size(32px);
      left: size(-19px);

      @include vp-767 {
        width: size(400px);
        top: size(15px);
        left: 0;
      }
    }

    .inner-image-text__description {
      padding: size(98px) 0;

      @include vp-1023 {
        padding: size(75px) size(20px);
      }
    }
  }

  &--block-7 {
    .inner-image-text__image {
      width: size(668px);
      top: 0;
      left: 0;

      @include vp-767 {
        width: size(400px);
      }
    }

    .inner-image-text__description {
      padding: size(142px) 0;

      @include vp-1023 {
        padding: size(83px) size(20px);
      }
    }
  }

  &--block-8 {
    .inner-image-text__content {
      background-color: $color-medium-slate-blue;
      display: flex;
    }

    .inner-image-text__description {
      padding: size(65px) size(105px);

      @include vp-1023 {
        padding: size(39px);
      }

      @include vp-767 {
        padding: size(20px);
      }
    }
  }

  &--block-9 {
    .inner-image-text__content {
      background-color: $color-medium-slate-blue;
      display: flex;
    }

    .inner-image-text__description {
      padding: size(110px) size(105px);

      @include vp-1023 {
        padding: size(40px);
      }

      @include vp-767 {
        padding: size(20px);
      }
    }
  }

  &--grid {
    .inner-image-text__content {
      display: grid;
      grid-template-rows: max-content;
    }

    .inner-image-text__image {
      position: relative;
      grid-column: 1 / -1;
      grid-row: 1 / -1;
      width: auto;
      height: auto;
      display: flex;
      flex-direction: column;

      picture {
        min-height: 100%;
      }

      img {
        min-height: 100%;
        min-width: 100%;
        object-fit: cover;
      }
    }

    .inner-image-text__description {
      display: grid;
      grid-template-columns: 1fr 2fr;
      position: relative;
      align-content: center;
      grid-column: 1 / -1;
      grid-row: 1 / -1;
      width: 100%;
      height: 100%;

      @include vp-767 {
        grid-template-columns: 1fr;
      }
    }


    .inner-simple-text {
      grid-column: 2;
    }
  }

  &--block-10 {
    .inner-image-text__description {
      padding: size(60px) size(20px);

      @include vp-767 {
        padding: size(30px) size(20px) size(125px);
      }
    }
  }

  &__content {
    display: grid;
    position: relative;
    grid-template-columns: minmax(size(420px), auto) minmax(auto, size(820px));
    grid-template-rows: auto;
    background-color: $color-night-rider;
    border-radius: size(20px);
    overflow: hidden;

    @include vp-1023 {
      display: flex;
      grid-template-columns: auto;
    }
  }

  &__description {
    color: $color-default-white;
    grid-column: 2 / 3;
    align-self: center;
    padding: size(75px) 0;
    margin: 0;
    z-index: 5;

    @include vp-1023 {
      grid-column: 1 / -1;
      padding: size(75px) size(20px);
    }
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;

    @include vp-1023 {
      width: 100%;
    }

    @include vp-767 {
      bottom: 0;
      top: auto;
    }
  }

  &__image--bottom {
    position: absolute;
    bottom: 0;
    top: auto;
    left: auto;
  }

  & br.desktop-only {
    @include vp-1023 {
      display: none;
    }
  }

  br.tablet-only {
    display: none;

    @include vp-1023 {
      display: block;
    }

    @include vp-767 {
      display: none;
    }
  }

  & br.mobile-only {
    display: none;

    @include vp-767 {
      display: initial;
    }
  }
}
