.inner-cases {
  margin: size(150px) 0 0 0;

  @include vp-767 {
    margin: size(100px) 0 0 0;
  }

  .project__text-list {
    opacity: 1;
    transform: translate3d(0, 0, 0);

    @include vp-767 {
      margin: 0;
      padding: size(6px) 0 0 0;
    }
  }

  .project__text-title {
    font-size: size(20px);
    line-height: size(25px);
    margin-top: 0;
  }

  .project__text-note {
    margin: auto 0 size(9px) 0;

    @include vp-1023 {
      margin: 0 size(11px) 0 0;
    }
  }
}
