.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;

  display: flex;
  flex-direction: column;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  background-color: rgba(#000000, 0.75);
  transform: scale(1.2) translateZ(0);
  backface-visibility: hidden;
  visibility: hidden;
  opacity: 0;

  transition: opacity $trans-modal,
  transform $trans-modal,
  visibility $trans-modal;

  pointer-events: none;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &__wrapper {
    position: relative;

    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 100%;
    padding: size(60px) size(40px);

    @include vp-767 {
      padding: size(60px) size(16px);
    }
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    cursor: pointer;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;

    &:hover {
      + .modal__content .modal__close-btn {
        opacity: 1;
      }
    }
  }

  &__content {
    position: relative;

    width: size(600px);
    padding: size(40px);

    background-color: $color-default-white;

    @include vp-767 {
      width: 100%;
      padding: size(20px);
    }
  }

  &__close-btn {
    position: absolute;
    top: size(22px);
    right: size(22px);
    z-index: 1;

    width: size(40px);
    height: size(40px);
    padding: 0;
    transition: all $trans-default;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;

    background: $color-transparent;
    border: none;
    cursor: pointer;
    opacity: 0.8;

    &:hover {
      opacity: 1;
    }

    svg {
      height: size(30px);
      width: size(30px);
      stroke: $color-default-white;
    }
  }

  &--responsive {
    .modal__content {
      width: 100%;
    }
  }

  &--fit-content {
    .modal__content {
      width: auto;

      @include vp-767 {
        width: 100%;
      }
    }
  }

  &--no-scale {
    transform: none;
  }

  &--preload {
    transition: none;
  }

  &.is-active {
    transform: scale(1) translateZ(0);
    visibility: visible;
    opacity: 1;

    pointer-events: auto;
  }

  &--form {
    .modal__content {
      width: 100%;
      max-width: size(585px);
      padding: size(40px);
      border-radius: size(20px);

      @include vp-767 {
        height: 100%;
        border-radius: 0;
        padding: 125px 20px 50px 20px;
      }
    }

    .modal__close-btn {
      top: size(-18px);
      right: size(-68px);
      width: size(25px);
      height: size(26px);

      @include vp-767 {
        display: flex;
        align-items: center;
        justify-content: center;
        width: size(40px);
        height: size(40px);
        top: size(21px);
        right: size(21px);
        border: size(1px) solid rgba($color-default-black, 0.15);
        border-radius: size(5px);
      }
    }

    .modal__close-btn svg {
      height: 100%;
      width: 100%;

      @include vp-767 {
        stroke: $color-default-black;
        width: 14px;
        height: 14px;
      }
    }

    .modal__wrapper {
      @include vp-767 {
        padding: 0;
      }
    }
  }
}
