.review {
  &__list {
    display: grid;
    gap: size(20px);
    grid-template-columns: repeat(4, 1fr);
    list-style: none;
    margin: 0;
    padding: 0;

    @include vp-1023 {
      grid-template-columns: repeat(2, 1fr);
    }

    @include vp-767 {
      grid-template-columns: 1fr;
    }
  }

  &__item {
    border-radius: size(20px);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: size(20px);
    position: relative;
    min-height: size(295px);
    transition: filter $trans-default;
    transform: translateZ(0);

    .review__link {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      min-height: inherit;
      display: inherit;
      flex-direction: inherit;
      padding: inherit;
      border-radius: inherit;

      &[href=""] {
        pointer-events: none;
      }
    }

    @include vp-767 {
      height: size(295px);
      width: size(400px);
    }

    &--bg-green {
      background-color: $color-emerald;

      &:hover {
        filter: drop-shadow(0 20px 40px rgba($color-emerald, 0.5)) !important;
      }
    }

    &--bg-purple {
      background-color: $color-light-slate-blue;

      &:hover {
        filter: drop-shadow(0 20px 40px rgba($color-light-slate-blue, 0.5)) !important;
      }
    }

    &--bg-orange {
      background-color: $color-burnt-orange;

      &:hover {
        filter: drop-shadow(0 20px 40px rgba($color-burnt-orange, 0.5)) !important;
      }
    }

    &--bg-gray {
      background-color: $color-pink-swan;

      &:hover {
        filter: drop-shadow(0 20px 40px rgba($color-pink-swan, 0.5)) !important;
      }
    }

    &--radius-round {
      border-radius: size(150px);

      .review__author {
        align-items: center;
      }

      .review__project {
        max-width: 64%;
      }
    }

    &--radius-right {
      border-radius: size(20px) size(150px) size(150px) size(20px);
      padding-right: size(55px);
    }

    &--tablet-bottom {
      grid-row: -1;
    }

    .review:not(.review--links) & {
      filter: none;
    }

    .review--links &:has(.review__link[href=""]) {
      filter: none;
    }
  }

  &__item--type-video {
    .review__author {
      align-items: center;
      color: $color-default-white;
    }
  }

  &__item--type-big {
    grid-column: span 2;

    @include vp-1023 {
      grid-column: span 1;
    }

    .review__text {
      font-size: size(40px);
      line-height: size(45px);

      @include vp-1023 {
        font-size: size(20px);
        line-height: size(25px);
      }
    }
  }

  &__item--pr-40-50 {
    padding-right: size(40px);

    @include vp-1023 {
      padding-right: size(50px);
    }
  }

  &__item--text-big p {
    font-size: size(40px);
    line-height: size(45px);
    white-space: break-spaces;

    @include vp-1023 {
      font-size: size(20px);
      line-height: size(25px);
    }
  }

  &__item--center {
    .review__text {
      position: absolute;
      top: 50%;
      left: 50%;
      width: size(251px);
      transform: translate(-50%, -50%);
      margin: 0;
      text-align: center;

      @include vp-767 {
        width: size(345px);
      }
    }

    .review__author {
      text-align: center;
    }
  }

  &__picture {
    left: 0;
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;

    &::before {
      background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0.5) 100%);
      content: "";
      left: 0;
      height: 100%;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  &__project {
    white-space: break-spaces;
  }

  picture {
    display: block;
    height: 100%;
    width: 100%;
  }

  img {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }

  &__play {
    align-items: center;
    background-color: $color-transparent;
    backface-visibility: hidden;
    border: none;
    cursor: pointer;
    display: flex;
    height: 100%;
    justify-content: center;
    left: 50%;
    padding: 0;
    position: absolute;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    width: 100%;

    @media (hover: hover), screen and (min-width: 0 \0
    ) {
      &:hover {
        .review__play-icon {
          opacity: 1;
        }
      }
    }
  }

  &__play-icon {
    backface-visibility: hidden;
    height: size(58px);
    opacity: 0.4;
    pointer-events: none;
    transition: opacity $trans-default;
    transform: translateZ(0);
    width: size(50px);
  }

  &__text {
    font-size: size(20px);
    line-height: size(25px);

    &.review__text-big-title {
      font-size: size(40px);
      line-height: size(45px);

      @include vp-1023 {
        font-size: size(20px);
        line-height: size(25px);
      }
    }

    p {
      margin: 0;

      &:not(:last-child) {
        margin: 0 0 size(10px) 0;
      }

      & br.only-desktop {
        @include vp-767 {
          display: none;
        }
      }

      & br.only-mobile {
        display: none;

        @include vp-767 {
          display: initial;
        }
      }

      & span.no-wrap-mobile {
        white-space: initial;

        @include vp-767 {
          white-space: nowrap;
        }
      }
    }
  }

  &__author {
    display: flex;
    flex-direction: column;
    font-size: size(15px);
    line-height: size(20px);
    margin: auto 0 0 0;
    padding: size(15px) 0 0 0;

    & br.only-desktop {
      @include vp-1023 {
        display: none;
      }
    }
  }

  &__author--center {
    text-align: center;
  }

  &__author--center {
    text-align: center;
  }
}
