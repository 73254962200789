.media-links {
  display: grid;
  gap: size(20px);
  padding: 0;
  grid-template-columns: repeat(3, 1fr);
  list-style: none;
  margin: 0;

  @include vp-1023 {
    grid-template-columns: repeat(2, 1fr);
  }

  @include vp-767 {
    grid-template-columns: 1fr;
  }

  &__item {
    position: relative;
    min-height: size(295px);
    border-radius: size(20px);
    overflow: hidden;

    @include vp-767 {
      grid-column: 1 / span 1;
    }
  }

  .media-links__item--big {
    grid-column: span 2;

    @include vp-1023 {
      grid-column: span 1;
    }

    @include vp-767 {
      grid-column: 1 / span 1;
    }
  }

  .media-links__item--big-right {
    grid-column: 2 / span 2;

    @include vp-1023 {
      grid-column: 2 / span 1;
    }

    @include vp-767 {
      grid-column: 1 / span 1;
    }
  }

  .media-links__item--big-left {
    grid-column: 1 / span 2;

    @include vp-1023 {
      grid-column: 1 / span 1;
    }

    @include vp-767 {
      grid-column: 1 / span 1;
    }
  }

  &__wrapper {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    width: 100%;
    padding: size(20px);
  }

  &__picture {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: -1;

    picture {
      display: block;
      height: 100%;
      width: 100%;
    }

    img {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }

  &__title {
    max-width: 100%;
    margin: 0;
    font-size: size(40px);
    line-height: size(45px);
    font-weight: 400;
    color: $color-default-white;

    @include vp-1023 {
      font-size: size(20px);
      line-height: size(25px);
    }

    @include vp-767 {
      font-size: size(20px);
      line-height: size(25px);
    }

    & br.only-desktop {
      @include vp-1023 {
        display: none;
      }
    }

    & br.only-mobile {
      display: none;

      @include vp-767 {
        display: initial;
      }
    }
  }

  &__link {
    background: rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(size(40px));
    border-radius: size(20px);
    color: $color-default-white;
    font-size: size(15px);
    line-height: size(20px);
    padding: size(9px) size(15px) size(11px);
    position: relative;
    transition: all $trans-default;
    transform: translate3d(0, 0, 0);
    z-index: 6;
    margin: auto 0 0;

    @include vp-1023 {
      font-size: size(13px);
    }

    @include vp-767 {
      font-size: size(15px);
    }

    @media (hover: hover), screen and (min-width: 0 \0
    ) {
      &:hover {
        background-color: $color-default-white;
        color: $color-default-black;
      }
    }
  }
}
