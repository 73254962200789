.inner-tools {
  margin: size(150px) 0 0;

  @include vp-1023 {
    margin: size(100px) 0 0;
  }

  @include vp-767 {
    margin: size(100px) 0 0;
  }

  &__content {
    margin: 0 0 size(65px) auto;
    padding: 0 size(20px);
    width: 66.2%;

    &.no-margin {
      margin: 0
    }

    @include vp-1023 {
      padding: 0;
      width: 100%;
    }

    @include vp-767 {
      margin: 0 0 size(40px) 0;

      &.no-margin {
        margin: 0
      }
    }
  }

  &__title {
    font-size: size(40px);
    font-weight: 400;
    line-height: size(45px);
    margin: 0 0 size(40px) 0;

    @include vp-767 {
      font-size: size(20px);
      line-height: size(25px);
    }

    &--large {
      @include vp-767 {
        font-size: size(40px);
        line-height: size(45px);
      }
    }

    &--mobile-margin-small {
      @include vp-767 {
        margin-bottom: size(20px);
      }
    }
  }

  &__text {
    font-size: size(20px);
    line-height: size(25px);

    p {
      margin: 0;

      &:not(:last-child) {
        margin: 0 0 size(25px) 0;
      }
    }

    &--mobile-small-text {
      @include vp-767 {
        font-size: size(15px);
        line-height: size(20px);
      }
    }
  }

  &__list {
    display: grid;
    gap: size(20px);
    grid-template-columns: repeat(3, 1fr);
    list-style: none;
    margin: 0;
    padding: 0;

    @include vp-1023 {
      grid-template-columns: 1fr 1fr;
    }

    @include vp-767 {
      grid-template-columns: 1fr;
    }
  }

  &__item {
    align-items: center;
    background-color: $color-default-white;
    border-radius: size(20px);
    display: flex;
    flex-direction: column;
    min-height: size(220px);
    padding: size(20px);

    &--border-round {
      border-radius: size(110px);
    }

    &--border-right {
      border-radius: size(20px) size(110px) size(110px) size(20px);
    }
  }

  &__item img {
    height: size(80px);
    width: size(80px);
  }

  &__heading {
    font-size: size(20px);
    font-weight: 400;
    line-height: size(25px);
    margin: size(20px) 0 size(15px);
    text-align: center;
  }

  &__description {
    color: $color-pink-swan;
    font-size: size(15px);
    line-height: size(20px);
    margin: 0;
    text-align: center;
    white-space: break-spaces;

    p:first-child {
      margin-top: 0;
    }

    p:last-child {
      margin-bottom: 0;
    }
  }

  &--2-items {

    &.inner-tools__list {

      &.two-part {
        width: 66.2%;

        @include vp-1023 {
          width: 100%;
        }
      }

      margin-left: auto;
      margin-right: 0;
      grid-template-columns: repeat(2, 1fr);

      @include vp-1023 {
        width: 100%;
        grid-template-columns: 1fr;
      }
    }
  }

  br.only-desktop {
    @include vp-1023 {
      display: none;
    }
  }

  br.only-tablet {
    display: none;

    @include vp-1023 {
      display: block;
    }

    @include vp-767 {
      display: none;
    }
  }

  br.only-mobile {
    display: none;

    @include vp-767 {
      display: block;
    }
  }
}
