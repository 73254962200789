$spacings: (
        "0": 0,
        "5": size(5px),
        "10": size(10px),
        "15": size(15px),
        "20": size(20px),
        "25": size(25px),
        "30": size(30px),
        "35": size(35px),
        "40": size(40px),
        "45": size(45px),
        "50": size(50px),
        "65": size(65px),
        "75": size(75px),
        "100": size(100px),
        "130": size(130px),
        "150": size(150px),
);

$breakpoints: (
        "md": 1023px,
        "sm": 767px
);

@each $prop, $prop-abbrev in (
        margin: m,
        padding: p,
        font-size: fs
) {
  @each $spacing, $spacing-var in $spacings {

    .#{$prop-abbrev}-#{$spacing} {
      #{$prop}: $spacing-var !important;
    }

    @each $side, $side-abbrev in (
            top: t,
            right: r,
            bottom: b,
            left: l,
    ) {

      .#{$prop-abbrev}#{$side-abbrev}-#{$spacing} {
        #{$prop}-#{$side}: $spacing-var !important;
      }
    }
  }
}

@each $prop, $prop-abbrev in (
        margin: m,
        padding: p,
        font-size: fs
) {
  @each $breakpoint, $breakpoint-var in $breakpoints {
    @each $spacing, $spacing-var in $spacings {

      .#{$prop-abbrev}-#{$breakpoint}-#{$spacing} {
        @media screen and (max-width: $breakpoint-var) {
          #{$prop}: #{$spacing-var} !important;
        }
      }

      @each $side, $side-abbrev in (
              top: t,
              right: r,
              bottom: b,
              left: l
      ) {

        .#{$prop-abbrev}#{$side-abbrev}-#{$breakpoint}-#{$spacing} {
          @media screen and (max-width: $breakpoint-var) {
            #{$prop}-#{$side}: $spacing-var !important;
          }
        }
      }
    }
  }
}
