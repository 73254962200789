.custom-input,
.custom-textarea {
  position: relative;

  display: flex;
  width: 100%;

  &--big-height {
    label {
      textarea {
        min-height: size(136px);
      }
    }
  }

  label {
    display: flex;
    align-items: center;
    width: 100%;

    input,
    textarea {
      border-radius: size(10px);
      background-color: $color-white-smoke;
      border: none;
      flex-grow: 1;
      outline: none;
      min-height: size(65px);
      padding: size(34px) size(20px) size(10px);
      transition: all $trans-default;

      &:hover {
        + .custom-input__label,
        + .custom-textarea__label {
          color: $color-default-black;
        }
      }

      &:focus {
        + .custom-input__label,
        + .custom-textarea__label {
          color: rgba($color-default-black, 0.3);
        }
      }
    }

    textarea {
      resize: vertical;
    }
  }

  &__label {
    color: rgba($color-default-black, 0.3);
    font-size: size(15px);
    line-height: size(20px);
    left: size(20px);
    position: absolute;
    pointer-events: none;
    top: 0;
    transform: translate3d(0, size(30px), 0);
    transition: all $trans-default;

    &.is-focus {
      transform: translate3d(0, calc(#{size(17.5px)}), 0);
    }
  }

  &__error {
    position: absolute;
    right: 0;
    bottom: size(-5px);

    color: $color-torch-red;

    opacity: 0;

    transition: opacity $trans-default;
  }

  &.is-invalid {
    input,
    textarea {
      //border: 2px solid $color-torch-red;
      background: rgba(235, 87, 87, 0.1);

      &:focus,
      &:hover {
        //border: 2px solid rgba($color-torch-red, 0.5);
      }
    }

    .custom-input__error {
      opacity: 1;
    }
  }

  /*&.is-valid {
    input {
      border: 2px solid $color-malachite;

      &:focus,
      &:hover {
        border: 2px solid rgba($color-malachite, 0.5);
      }
    }
  }*/
}
