.inner-picture {
  border-radius: size(20px);
  overflow: hidden;
  position: relative;

  &--composition {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0 size(100px);

    img::before {
      opacity: 0;
    }

    @include vp-1023 {
      padding: 0 size(50px);
    }

    @include vp-767 {
      padding: 0;
    }
  }

  &--composition-desktop {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0 size(100px);

    & > picture {
      display: none;
    }

    img::before {
      opacity: 0;
    }

    @include vp-1023 {
      padding: 0 size(50px);
    }

    @include vp-767 {
      padding: 0;

      & > picture {
        display: block;
        width: inherit;
        height: inherit;

        img {
          width: inherit;
          height: inherit;
        }
      }

      .picture-item {
        display: none;
      }
    }
  }

  &--standart {
    width: size(1240px);
    height: size(600px);
    max-width: 100%;

    @include vp-1023 {
      width: 100%;
      height: size(361px);
    }

    @include vp-767 {
      width: size(400px);
      height: size(400px);
    }
  }

  &--big {
    width: size(1240px);
    height: size(780px);

    @include vp-1023 {
      width: 100%;
      height: size(469px);
    }

    @include vp-767 {
      width: size(400px);
      height: size(570px);
    }
  }

  &--big-plus {
    width: size(1240px);
    height: size(868px);

    @include vp-1023 {
      width: 100%;
      height: size(469px);
    }

    @include vp-767 {
      width: size(400px);
      height: size(570px);
    }
  }

  &--mobile-standart {
    @include vp-767 {
      width: size(400px);
      height: size(400px);
    }
  }

  &--violet {
    background-color: $color-violet;
  }

  &--crimson {
    background-color: $color-crimson;
  }

  &--gainsboro {
    background-color: $color-gainsboro;
  }

  &--margin-top-extra-big {
    margin: size(150px) 0 0;

    @include vp-767 {
      margin: size(100px) 0 0;
    }
  }

  &--margin-top-big {
    margin: size(65px) 0 0;

    @include vp-767 {
      margin: size(40px) 0 0;
    }
  }

  &--margin-top-middle {
    margin: size(40px) 0 0;

    @include vp-767 {
      margin: size(20px) 0 0;
    }
  }

  &--margin-top-small {
    margin: size(20px) 0 0;

    @include vp-767 {
      margin: size(15px) 0 0;
    }
  }

  &--margin-top-150-40 {
    margin: size(150px) 0 0;

    @include vp-767 {
      margin: size(40px) 0 0;
    }
  }

  &--margin-top-65-100 {
    margin: size(65px) 0 0;

    @include vp-767 {
      margin: size(100px) 0 0;
    }
  }

  &--mobile-no-margin {
    @include vp-767 {
      margin: 0;
    }
  }


  /*
  &::before {
    content: "";
    display: block;
    padding-bottom: calc(0.4838 * 100%);
    width: 100%;
  }
  */

  @include vp-767 {
    &::before {
      padding-bottom: calc(1 * 100%);
    }
  }

  /*
  picture {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
  */

  img {
    height: auto;
    width: 100%;
  }

  &.desktop {
    @include vp-1023 {
      display: none;

      + p {
        display: none;
      }
    }
  }
}
