.section-heading {
  align-items: center;
  display: flex;
  margin: 0 0 size(40px) 0;
  min-height: size(40px);

  &--no-counter {
    margin: 0 0 size(40px) size(420px);
  }

  &__count {
    color: $color-pink-swan;
    line-height: size(20px);
    padding: 0 0 0 size(20px);
    width: size(420px);
    flex-shrink: 0;

    @include vp-1279 {
      //width: 35%;
    }

    @include vp-1023 {
      padding: 0;
      width: 40%;
    }

    @include vp-767 {
      width: 26%;
    }
  }

  &__title {
    font-size: size(15px);
    line-height: size(20px);
    font-weight: 400;
    margin: 0;
    padding: 0 0 0 size(20px);

    @include vp-1023 {
      padding: 0;
    }
  }

  &__link {
    margin: 0 0 0 auto;

    &--mobile {
      display: none;
    }

    @include vp-767 {
      &--desktop {
        display: none;
      }

      &--mobile {
        display: block;
      }
    }
  }
}
