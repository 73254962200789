.file-upload {
  display: flex;
  flex-direction: column;

  &__input {
    position: absolute;
    width: size(1px);
    height: size(1px);
    margin: size(-1px);
    clip: rect(0 0 0 0);

    &:focus + .file-upload__button {
      color: $color-light-slate-blue;
    }
  }

  &__button {
    border-radius: size(40px);
    background-color: $color-white-smoke;
    cursor: pointer;
    font-size: size(15px);
    line-height: size(20px);
    padding: size(22.5px) size(20px);
    text-align: center;
    transition: color $trans-default;
    width: 100%;

    @media (hover: hover) {
      &:hover {
        color: $color-light-slate-blue;
      }
    }
  }

  &__result {
    border-radius: size(10px);
    background-color: $color-white-smoke;
    display: none;
    font-size: size(15px);
    line-height: size(20px);
    //margin: 0 0 size(15px) 0;
    margin: 0;
    padding: size(22.5px) size(20px);
    position: relative;

    &.is-active {
      display: flex;
    }

    &.is-error {
      background: rgba(235, 87, 87, 0.1);
      display: flex;
    }
  }

  &__file-name {
    max-width: 80%;
    word-wrap: break-word;
  }

  &__delete {
    align-items: center;
    background-color: $color-transparent;
    border: none;
    cursor: pointer;
    display: flex;
    height: size(20px);
    justify-content: center;
    padding: 0;
    position: absolute;
    right: size(20px);
    top: 50%;
    transform: translateY(-50%);
    width: size(20px);

    @media (hover: hover) {
      &:hover {
        svg {
          stroke: $color-default-black;
        }
      }
    }
  }

  &__delete-icon {
    height: size(12px);
    stroke: rgba($color-default-black, 0.3);
    transition: all $trans-default;
    width: size(12px);
  }
}
