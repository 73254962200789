.inner-stack {
  margin: size(65px) 0 0 0;

  @include vp-767 {
    margin: size(40px) 0 0 0;
  }

  &__list {
    display: grid;
    gap: size(20px);
    grid-template-columns: 1fr 1fr;
    list-style: none;
    margin: 0 0 0 auto;
    padding: 0;
    max-width: size(820px);

    @include vp-1023 {
      max-width: unset;
    }

    @include vp-767 {
      grid-template-columns: 1fr;
    }
  }

  img {
    height: size(80px);
    width: size(80px);
  }

  &__item {
    align-items: center;
    background-color: $color-default-white;
    border-radius: size(20px);
    display: flex;
    padding: size(20px);
  }

  &__text {
    font-size: size(20px);
    line-height: size(25px);
    margin: 0 0 0 size(20px);
    max-width: size(155px);
    text-align: center;
    width: 100%;
  }
}
