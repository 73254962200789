.maps {
  display: grid;
  grid-template-columns: 1fr 2fr;

  @include vp-1023 {
    display: flex;
    flex-wrap: wrap;
    gap: 0;
  }

  &__item {
    grid-column: 1 / -1;
    grid-row: auto;
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 0;
    margin: size(150px) 0 0;

    @include vp-1023 {
      grid-template-columns: 1fr;
      width: 100%;
      gap: 0;
      margin: size(100px) 0 0;
    }

    &--no-margin {
      margin: 0;

      @include vp-1023 {
        margin: 0;
      }
    }
  }

  &__aside {
    padding: 0;

    @include vp-1023 {
      margin: 0 0 size(20px);
    }

    @include vp-767 {
      margin: 0 0 size(20px);
    }

    .aside__heading,
    .aside__link {
      margin: 0 0 size(4px);
    }
  }

  .map {
    justify-self: end;
    max-width: 100%;
    width: size(799px);
    height: size(546px);
    border-radius: size(25px);

    @include vp-1023 {
      width: size(708px);
      height: size(435px);
    }

    @include vp-767 {
      width: size(400px);
      height: size(273px);
    }
  }
}
