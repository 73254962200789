.slider-block {
  padding: size(150px) 0 0;

  @include vp-1023 {
    padding: size(100px) 0 0;
  }

  &__wrapper {
    height: 250vh;
  }

  &__inner {
    position: sticky;
    top: calc(50% - #{size(300px)});

    @include vp-1023 {
      top: calc(50% - 150px);
      min-height: size(291px);
    }
  }
}
