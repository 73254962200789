.feedback-content {
  display: grid;
  grid-template-columns: 1fr;
  padding: 0 0 size(150px) 0;

  @include vp-767 {
    padding: 0 0 size(125px) 0;
  }

  &__title {
    max-width: size(940px);
    font-size: size(60px);
    line-height: size(65px);
    font-weight: 400;
    margin: size(72px) 0 size(152px);
    padding: 0 size(20px);
    white-space: break-spaces;

    @include vp-1023 {
      font-size: size(40px);
      line-height: size(45px);
    }

    @include vp-767 {
      font-size: size(25px);
      line-height: size(30px);
      padding: 0;
      margin: size(42px) 0 size(105px);
    }
  }
}
