.inner-simple-text {
  width: 100%;
  margin: 0 0 0 auto;
  padding: 0 size(20px);
  font-size: size(20px);
  line-height: size(25px);
  white-space: break-spaces;

  p {
    white-space: break-spaces;
  }

  @include vp-1023 {
    padding: 0;
  }

  @include vp-767 {
    font-size: size(15px);
    line-height: size(20px);
  }

  &.step-description:not(:last-child) {
    margin: 0 0 size(25px);

    &.inner-simple-text--font-40-35-20 {
      margin: 0 0 size(40px);
    }

    @include vp-1023 {
      margin: 0 0 size(20px) 0;

      &.inner-simple-text--font-40-35-20 {
        margin: 0 0 size(40px);
      }
    }
  }

  &--margin-10-10-5 p:not(:last-child) {
    margin: 0 0 size(10px) 0;

    @include vp-1023 {
      margin: 0 0 size(10px) 0;
    }

    @include vp-767 {
      margin: 0 0 size(5px) 0;
    }
  }

  &--font-small {
    font-size: size(15px);
    line-height: size(20px);
  }

  &--large {
    @include vp-767 {
      font-size: size(20px);
      line-height: size(25px);

      p:not(:last-child) {
        margin: 0 0 size(25px);
      }
    }
  }

  &--big {
    font-size: size(40px);
    line-height: size(45px);

    @include vp-767 {
      font-size: size(20px);
      line-height: size(25px);
    }
  }

  &--font-20-20-15 {
    font-size: size(20px);
    line-height: size(25px);

    @include vp-1023 {
      font-size: size(20px);
      line-height: size(25px);
    }

    @include vp-767 {
      font-size: size(15px);
      line-height: size(20px);
    }
  }

  &--font-20-20-20 {
    font-size: size(20px);
    line-height: size(25px);

    @include vp-1023 {
      font-size: size(20px);
      line-height: size(25px);
    }

    @include vp-767 {
      font-size: size(20px);
      line-height: size(25px);
    }
  }

  &--font-20-15-15 {
    font-size: size(20px);
    line-height: size(25px);

    @include vp-1023 {
      font-size: size(15px);
      line-height: size(20px);
    }

    @include vp-767 {
      font-size: size(15px);
      line-height: size(20px);
    }
  }

  &--font-40-35-20 {
    font-size: size(40px);
    line-height: size(45px);

    @include vp-1023 {
      font-size: size(35px);
      line-height: size(40px);
    }

    @include vp-767 {
      font-size: size(20px);
      line-height: size(25px);
    }
  }

  &--font-40-20-20 {
    font-size: size(40px);
    line-height: size(45px);

    @include vp-1023 {
      font-size: size(20px);
      line-height: size(25px);
    }

    @include vp-767 {
      font-size: size(20px);
      line-height: size(25px);
    }
  }

  &--no-padding-right {
    padding: 0 0 0 size(20px);
  }

  .inner-grid__aside & {
    max-width: size(295px);
    margin: 0;
    font-size: size(15px);
    line-height: size(20px);

    @include vp-1023 {
      max-width: 100%;
      font-size: size(20px);
      line-height: size(25px);
    }

    @include vp-767 {
      font-size: size(15px);
      line-height: size(20px);
    }
  }

  p {
    margin: 0;

    &:not(:last-child) {
      margin: 0 0 size(25px);

      @include vp-1023 {
        margin: 0 0 size(20px) 0;
      }

      @include vp-767 {
        margin: 0 0 size(5px) 0;
      }
    }
  }

  &--mobile-margin {
    @include vp-767 {
      p:not(:last-child) {
        margin: 0 0 size(20px);
      }
    }
  }

  &--mobile-margin-10 {
    @include vp-767 {
      p:not(:last-child) {
        margin: 0 0 size(10px);
      }
    }
  }

  &--mobile-margin-2 {
    @include vp-767 {
      p {
        margin: 0 0 size(20px);
      }
    }
  }

  &--mobile-margin-small {
    @include vp-767 {
      p:not(:last-child) {
        margin: 0 0 size(5px);
      }
    }
  }

  &--mobile-margin-middle {
    @include vp-767 {
      p:not(:last-child) {
        margin: 0 0 size(25px);
      }
    }
  }

  &--mobile-margin-big {
    @include vp-767 {
      p:not(:last-child) {
        margin: 0 0 size(30px);
      }
    }
  }

  &--margin-top {
    margin: size(20px) 0 0;
  }

  &--margin-top-extra-small {
    margin: size(5px) 0 0;

    @include vp-1023 {
      margin: 0;
    }
  }

  &--no-margin-top {
    margin: 0;

    @include vp-1023 {
      margin: 0;
    }

    @include vp-767 {
      margin: 0;
    }
  }

  &--margin-top-40-20 {
    margin: size(40px) 0 0;

    @include vp-1023 {
      margin: size(20px) 0 0;
    }
  }

  &--mb-25-30 {
    margin: 0 0 size(25px);

    @include vp-767 {
      margin: 0 0 size(30px);
    }
  }

  &--mb-25-0 {
    margin: 0 0 size(25px);

    @include vp-767 {
      margin: 0 0 size(0px);
    }
  }

  &--margin-top-big {
    margin: size(65px) 0 0;

    @include vp-1023 {
      margin: size(40px) 0 0;
    }
  }

  &--margin-top-middle {
    margin: size(40px) 0 0;

    @include vp-1023 {
      margin: size(30px) 0 0;
    }
  }

  &--margin-bottom-small {
    margin-bottom: size(20px);

    @include vp-1023 {
      margin-bottom: size(20px);
    }
  }

  &--mt-mobile-negative-5 {
    @include vp-767 {
      margin-top: size(-5px);
    }
  }

  &--mobile-margin-top-big {
    @include vp-767 {
      margin: size(40px) 0 0;
    }
  }
}
