.success-tooltip {
  background-color: $color-default-white;
  box-shadow: 0 size(10px) size(40px) rgba(0, 0, 0, 0.1), 0 size(20px) size(40px) rgba(0, 0, 0, 0.05);
  border-radius: size(10px);
  opacity: 0;
  position: fixed;
  pointer-events: none;
  right: size(20px);
  top: size(20px);
  transition: all ease-out 0.5s;
  transform: translate3d(0, size(-100px), 0);
  z-index: 30;

  @include vp-767 {
    left: size(20px);
  }

  &.is-active {
    opacity: 1;
    pointer-events: all;
    transform: translate3d(0, 0, 0);
    transition: all ease-out 0.5s 0.6s;
  }

  &__wrapper {
    align-items: center;
    display: flex;
    padding: size(20px) size(100px) size(20px) size(20px);

    @include vp-767 {
      padding: size(15px) size(60px) size(15px) size(15px);
    }
  }

  &__close {
    background-color: $color-transparent;
    border: none;
    cursor: pointer;
    height: 100%;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: size(64px);

    @include vp-767 {
      width: size(50px);
    }

    @media (hover: hover) {
      &:hover {
        .success-tooltip__close-icon {
          stroke: $color-default-black;
        }
      }
    }
  }

  &__close-icon {
    height: size(14px);
    stroke: $color-pink-swan;
    transition: all $trans-default;
    width: size(14px);
  }

  &__status {
    align-items: center;
    background-color: $color-emerald;
    border-radius: 50%;
    display: flex;
    flex: 0 0 auto;
    justify-content: center;
    height: size(40px);
    margin: 0 size(20px) 0 0;
    width: size(40px);
  }

  &__icon {
    fill: none;
    stroke: $color-default-white;
  }

  &__title {
    font-size: size(15px);
    font-weight: 600;
    line-height: size(20px);
    margin: 0;
  }

  &__text {
    font-size: size(15px);
    line-height: size(20px);
    margin: 0;
  }
}
