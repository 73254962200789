.blog-page {
  padding: 0 0 size(150px) 0;

  &__title {
    font-size: size(60px);
    font-weight: 400;
    line-height: size(65px);
    margin: 0 0 size(125px);
    padding: size(67px) 0 0 size(20px);
    white-space: break-spaces;

    @include vp-1023 {
      font-size: size(50px);
      line-height: size(55px);
      margin: 0 0 size(50px) 0;
      padding: size(40px) 0 0;
    }

    @include vp-767 {
      font-size: size(40px);
      line-height: size(45px);
      margin: 0 0 size(55px) 0;
      padding: size(40px) 0 0;
    }
  }

  &__filter-list {
    display: flex;
    flex-wrap: wrap;
    margin: size(-5px) 0 size(85px);
    max-width: size(1000px);
    padding: size(50px) 0 0;
    width: 100%;

    @include vp-767 {
      margin: size(-5px) 0 size(40px);
    }
  }

  &__filter-item {
    display: flex;
    padding: size(5px) 0 0;

    &:not(:last-child) {
      margin: 0 size(5px) 0 0;
    }
  }

  &__filter-link {
    align-items: center;
    background-color: $color-default-white;
    border-radius: size(20px);
    backdrop-filter: blur(size(40px));
    color: $color-default-black;
    display: flex;
    font-size: size(15px);
    line-height: size(20px);
    padding: size(9px) size(15px) size(11px);
    white-space: nowrap;
    transition: all $trans-default;
    transform: translate3d(0, 0, 0);
    text-align: center;
    cursor: pointer;

    &.is-active {
      background-color: $color-default-black;
      color: $color-default-white;
    }

    span {
      pointer-events: none;
    }

    @include vp-1023 {
      font-size: size(15px);
    }

    @media (hover: hover) {
      &:hover {
        background-color: $color-default-black;
        color: $color-default-white;
      }
    }
  }
}
