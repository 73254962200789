.inner-projects {
  padding: size(150px) 0 0;

  @include vp-1023 {
    padding: size(100px) 0 0;
  }

  &__title {
    width: 66.2%;
    margin: size(-4px) 0 size(65px) auto;
    padding: 0 size(20px);
    font-size: size(40px);
    line-height: size(45px);
    font-weight: 400;

    @include vp-1023 {
      padding: 0;
      width: 100%;
    }

    @include vp-767 {
      margin: 0 0 size(40px) 0;
      font-size: size(20px);
      line-height: size(25px);
    }
  }
}
