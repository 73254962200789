.accordion {
  padding: size(20px) 0 0;
  width: 100%;

  &__element {
    overflow: hidden;
    background-color: $color-default-white;
    border-radius: size(20px);
    margin: 0 0 size(20px);

    &:last-child {
      margin: 0;
    }
  }

  &__element.is-active &__button {
    svg {
      transform: translateY(-50%) rotate(0deg);
      transition-duration: 0.3s;
    }
  }

  &__button {
    position: relative;
    width: 100%;
    padding: size(20px) size(50px) size(20px) size(20px);
    font-size: size(20px);
    line-height: size(25px);
    border: none;
    text-align: left;
    cursor: pointer;

    p {
      margin: 0;
    }

    @include vp-767 {
      padding: size(10px) size(50px) size(10px) size(20px);
      font-size: size(15px);
      line-height: size(20px);
    }

    svg {
      position: absolute;
      right: size(20px);
      top: 50%;
      width: size(13px);
      margin: 0 size(5px) 0 auto;
      transform: translateY(-50%) rotate(180deg);
      transition-duration: 0.3s;
      color: $color-manatee;
      fill-opacity: 0.8;

      @include vp-767 {
        width: size(25px);
      }
    }

    &:hover,
    &:focus-visible {
      opacity: 0.7;
      background-color: transparent;
    }
  }

  &__content {
    padding: 0 size(20px);
  }

  &__wrapper {
    padding: size(5px) 0 size(45px) 0;

    @include vp-767 {
      padding: size(5px) 0 size(25px) 0;
    }
  }

  .inner-simple-text {
    padding: 0;
  }
}
