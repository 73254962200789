.form-request {
  background: rgba(0, 0, 0, 0.75);
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  right: 0;
  scrollbar-width: none;
  top: 0;
  transition: opacity $trans-modal 0.2s,
  transform $trans-modal,
  visibility $trans-modal;
  visibility: hidden;
  z-index: 50;

  -ms-overflow-style: none;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    display: none;
  }

  &.is-active {
    opacity: 1;
    pointer-events: auto;
    transition: opacity $trans-modal 0s,
    transform $trans-modal,
    visibility $trans-modal;
    visibility: visible;

    .form-request__content {
      transform: translateX(0);
      transition: transform ease 0.3s 0.4s;
    }

    .form-request__close {
      opacity: 1;
      transition: opacity $trans-default 0.7s;
    }
  }

  &__overlay {
    cursor: pointer;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    &:hover {
      + .form-request__close .form-request__close-icon {
        opacity: 1;
      }
    }
  }

  &__content {
    background-color: $color-default-white;
    height: 100%;
    overflow-y: auto;
    max-width: size(625px);
    padding: size(60px) size(60px) size(50px);
    position: relative;
    transform: translateX(100%);
    transition: all $trans-default;
    width: 100%;

    @include vp-1023 {
      max-width: unset;
      padding: size(125px) size(40px) size(40px);
    }

    @include vp-767 {
      padding: size(125px) size(20px) size(40px);
    }
  }

  &__title {
    font-size: size(40px);
    font-weight: 400;
    line-height: size(45px);
    margin: 0 0 size(30px) 0;
    text-align: center;

    @include vp-1023 {
      margin: 0 0 size(40px) 0;
      text-align: left;
    }
  }

  &__close {
    background-color: $color-transparent;
    border: none;
    cursor: pointer;
    display: flex;
    left: size(585px);
    padding: 0;
    position: absolute;
    top: size(67px);
    opacity: 0;
    transition: opacity ease 0s 0s;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    @include vp-1023 {
      align-items: center;
      border: size(1px) solid rgba(0, 0, 0, 0.15);
      border-radius: size(5px);
      justify-content: center;
      height: size(40px);
      left: unset;
      right: size(20px);
      top: size(20px);
      width: size(40px);
      z-index: 2;
    }

    @media (hover: hover), screen and (min-width: 0 \0
    ) {
      &:hover {
        .form-request__close-icon {
          opacity: 1;
        }
      }
    }
  }

  &__close-icon {
    height: size(30px);
    opacity: 0.8;
    stroke: $color-default-white;
    transition: opacity $trans-default;
    width: size(30px);

    @include vp-1023 {
      height: size(14px);
      opacity: 1;
      stroke: $color-default-black;
      width: size(14px);
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    margin: size(-7.5px) 0 0;
    position: relative;
  }

  &__input-box {
    padding: size(7.5px) 0;
    position: relative;

    &.visually-hidden {
      padding: 0;
      position: absolute;
    }
  }

  &__button {
    background-color: $color-default-black;
    border: none;
    border-radius: size(40px);
    color: $color-default-white;
    cursor: pointer;
    font-size: size(15px);
    line-height: size(20px);
    margin: size(7.5px) 0 0 0;
    padding: size(22.5px) size(10px);
    position: relative;
    transition: all $trans-default;
    width: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    @media (hover: hover), screen and (min-width: 0 \0
    ) {
      &:hover {
        background-color: $color-light-slate-blue;
      }
    }

    &.is-loading {
      color: $color-transparent;
      background: $color-pink-swan;
      pointer-events: none;

      .form-request__button-loader {
        display: block;
      }
    }

    &.is-success {
      color: $color-transparent;
      background: $color-emerald;
      pointer-events: none;

      .form-request__button-success {
        display: block;
        fill: none;
        stroke: $color-default-white;
      }
    }
  }

  &__button-icon {
    display: none;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
  }

  &__note {
    color: $color-pink-swan;
    font-size: size(15px);
    line-height: size(20px);
    margin: size(15px) 0 0 0;
    text-align: center;
  }

  &__note-link {
    color: $color-pink-swan;
    text-decoration: underline;
    text-decoration-thickness: size(1px);
    text-underline-offset: size(4px);
    text-decoration-color: rgba(0, 0, 0, 0.15);
    transition: color $trans-default;

    @media (hover: hover), screen and (min-width: 0 \0
    ) {
      &:hover {
        color: $color-light-slate-blue;
      }
    }
  }
}
