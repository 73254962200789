.blog-posts {
  $root: &;

  @include vp-1023 {
    width: 100%;
  }

  &__list {
    display: grid;
    gap: size(65px) size(20px);
    grid-template-columns: repeat(4, 1fr);
    list-style: none;
    margin: 0;
    padding: 0;

    @include vp-1023 {
      gap: size(50px) size(15px);
      grid-template-columns: repeat(3, 1fr);
    }

    @include vp-767 {
      gap: size(40px);
      grid-template-columns: 1fr;
    }

    .blog-posts & {
      opacity: 0;
      transform: translate3d(0, size(50px), 0);

      &.is-active {
        opacity: 1;
        transition: all $trans-default;
        transform: translate3d(0, 0, 0);
      }
    }
  }

  &__item {
    display: grid;
    max-width: size(295px);
    min-height: size(291px);

    @include vp-767 {
      min-width: size(400px);
      min-height: size(341px);
    }

    &--radius-all {
      #{$root}__picture {
        border-radius: size(75px);

        @include vp-767 {
          border-radius: size(120px);
        }
      }
    }

    &--radius-right {
      #{$root}__picture {
        border-radius: size(20px) size(75px) size(75px) size(20px);

        @include vp-767 {
          border-radius: size(20px) size(120px) size(120px) size(20px);
        }
      }
    }
  }

  &__inner {
    position: relative;

    @media (hover: hover) {
      &:hover:not(.not-active) {
        .blog-posts__title {
          color: $color-light-slate-blue;
          text-decoration-color: $color-light-slate-blue;
        }
      }
    }
  }

  &__picture {
    border-radius: size(20px);
    overflow: hidden;
    margin: 0 0 size(20px) 0;

    picture {
      display: block;
    }

    img {
      height: size(150px);
      width: size(295px);

      @include vp-1023 {
        object-fit: cover;
      }

      @include vp-767 {
        height: size(200px);
        width: size(400px);
      }
    }
  }

  &__title {
    font-size: size(15px);
    font-weight: 400;
    line-height: size(20px);
    margin: 0 0 size(22px) 0;
    padding: 0 0 0 size(20px);
    transition: all $trans-default;
    text-decoration: underline;
    text-decoration-thickness: 1px;
    text-underline-offset: size(4px);
    text-decoration-color: rgba($color-default-black, 0.15);
    white-space: break-spaces;
  }

  &__shadow-link {
    &::after {
      content: "";
      left: 0;
      height: 100%;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  &__tags {
    margin: auto 0 0 0;
  }

  &__tag {
    align-items: center;
    background-color: $color-default-white;
    border-radius: size(20px);
    backdrop-filter: blur(size(40px));
    color: $color-default-black;
    display: inline-flex;
    font-size: size(15px);
    line-height: size(20px);
    padding: size(9px) size(15px) size(11px);
    transition: all $trans-default;
    transform: translate3d(0, 0, 0);
    text-align: center;
    cursor: pointer;

    @media (hover: hover) {
      &:hover {
        background-color: $color-default-black;
        color: $color-default-white;
      }
    }
  }
}
