.picture-item {
  position: relative;
  display: flex;
  justify-content: space-around;
  width: auto;
  height: auto;

  picture {
    position: relative;
  }

  img {
    position: relative;
    object-fit: cover;
  }

  &--center {
    align-self: flex-end;

    img {
      width: size(415px);
      height: size(525px);
      filter: drop-shadow(0 size(48px) size(80px) $color-black-opacity-50);

      @include vp-1023 {
        width: size(250px);
        height: size(315px);
      }

      @include vp-767 {
        width: size(247px);
        height: size(359px);
      }
    }
  }

  &--left {
    @include vp-767 {
      align-self: flex-start;
      padding: size(30px) 0;
    }

    img {
      width: size(335px);
      height: size(685px);
      filter: drop-shadow(0 size(38px) size(65px) $color-black-opacity-50);

      @include vp-1023 {
        width: size(199px);
        height: size(412px);
      }

      @include vp-767 {
        width: size(170px);
        height: size(342px);
      }
    }
  }

  &--right {
    @include vp-767 {
      align-self: flex-end;
      padding: size(30px) 0;
    }

    img {
      width: size(335px);
      height: size(685px);
      filter: drop-shadow(0 size(38px) size(65px) $color-black-opacity-50);

      @include vp-1023 {
        width: size(199px);
        height: size(412px);
      }

      @include vp-767 {
        width: size(170px);
        height: size(342px);
      }
    }
  }
}
