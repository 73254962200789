.wrapper {
  position: relative;

  display: flex;
  flex-direction: column;
  /* stylelint-disable-next-line */
  //min-height: calc(100 * var(--vh, 1vh));
  min-height: 150vh;

  .header,
  .footer {
    flex-shrink: 0;
  }

  main {
    flex-grow: 1;
    padding-top: 5.33333rem;
  }
}

.rouble {
  font-family: $ff-rouble;
  /* stylelint-disable */
  text-transform: lowercase !important;
  font-weight: 400 !important;
  /* stylelint-enable */
}

.visually-hidden {
  position: absolute;

  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;

  white-space: nowrap;

  border: 0;

  clip: rect(0 0 0 0);
  clip-path: inset(100%);
}

.scroll-lock-ios {
  position: fixed;

  overflow: hidden;
}

.scroll-lock {
  overflow: hidden;
}

.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.no-transition {
  /* stylelint-disable-next-line */
  transition: none !important;
}

.draft-mode-tile {
  background: #b63030;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 10px;
  border-radius: 0 20px 20px 0;
  color: white;
  font-size: 12px;
  line-height: 100%;
  font-weight: bold;
}

a[href^="/#"] {
  pointer-events: none;
  cursor: default;
}
